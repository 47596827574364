import { useSelector } from "react-redux";
import { Tab, Tabs, useMediaQuery, Grid, Button, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLazyGetUserCompletedCoursesQuery, useLazyGetUserOutstandingCoursesQuery } from "../../store/services/response.service";
import MainCard from "../../components/cards/MainCard";
import FeedbackButton from "../../utils/FeedBackButton";
import CourseCarousel from "./CourseCarousel";
import { useTheme } from "@mui/material/styles";
import { APP_URL } from "../../config";
import { useLazyGetCourseByIdQuery } from "../../store/services/university-service";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import { useGetTenancyAllowRetakeQuery } from "../../store/services/tenancy.service";
import { useDynamicBreakpoints } from '../../utils/breakpointUtils';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`course-tabpanel-${index}`}
            aria-labelledby={`course-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `course-tab-${index}`,
        'aria-controls': `course-tabpanel-${index}`,
    };
}

export default function LandingPage() {
    const theme = useTheme();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { matchDownSM, matchDownMD, matchDownLG } = useDynamicBreakpoints(drawerOpen);

    const { user } = useSelector((state) => state.auth);

    const userLoggedIn = JSON.parse(localStorage.getItem("user"));
    const isUser = userLoggedIn && userLoggedIn?.role === "";
    const tenancyId = userLoggedIn?.tenancyId;
    const [userCompletedCourses, setUserCompletedCourses] = useState([]);
    const [userCourses, setUserCourses] = useState([]);
    const [courseClicked, setCourseClicked] = useState(false);
    const [courseClickedData, setCourseClickedData] = useState(null);

    const [getUserCompletedCourses, {
        data: fetchedUserCompletedCourses,
        error: userCompletedCoursesError,
        isLoading: userCompletedCoursesLoading
    }] = useLazyGetUserCompletedCoursesQuery();
    const [getUserOutstandingCourses, {
        data: userOutstandingCourses,
        error: userOutstandingCoursesError,
        isLoading: userOutstandingCoursesLoading
    }] = useLazyGetUserOutstandingCoursesQuery();
    const [getCourseById] = useLazyGetCourseByIdQuery();

    const { data: tenancyRetakeData, isLoading: tenancyRetakeLoading, error: tenancyRetakeError } = useGetTenancyAllowRetakeQuery({ tenancyId: tenancyId });

    useEffect(() => {
        if (!user || !user.email) {
            window.location.href = "/login";
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            getUserCompletedCourses({ userEmail: user.email });
            getUserOutstandingCourses({ userEmail: user.email });
            setIsDataLoaded(true);
        }
    }, [user, getUserCompletedCourses, getUserOutstandingCourses]);

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const showCourseContent = async (course) => {
        try {
            const r = await getCourseById({ courseId: course.universityCourseId, includes: "" }).unwrap();
            if (r.status === "rejected") {
                throw new Error("Failed to fetch course content");
            } else {
                setCourseClickedData(r);
                setCourseClicked(true);
                window.scrollTo(0, document.body.scrollHeight);
            }
        } catch (e) {
            console.log("Caught error:", e);
        }
    };

    const handleCloseCourseContent = () => {
        setCourseClicked(false);
        setCourseClickedData(null);
        window.location.reload();
    };

    if (courseClicked && courseClickedData) {
        return (
            <>
                <Grid container justifyContent="space-between">
                    <Button onClick={handleCloseCourseContent}
                            variant="contained"
                            startIcon={<KeyboardArrowLeftTwoToneIcon />}
                            sx={{ mb: 2 }}
                    >
                        Back
                    </Button>
                </Grid>

                <MainCard content={false} sx={{ width: "100%", bgcolor: "common.white" }} border={false} boxShadow={true}>
                    <iframe
                        style={{ width: "100%", height: "80vh", border: "none" }}
                        title={`course`}
                        src={`${APP_URL}/${courseClickedData.courseContentPath}?useremailaddress=${user.email}&courseidentifier=${courseClickedData.courseName}`}
                    />
                </MainCard>
            </>
        );
    } else {
        return (
            <>
                <MainCard content={false} sx={{ width: "100%", bgcolor: "background.default", p: 1, minHeight: 'calc(100vh - 113px)' }} border={false} boxShadow={true}>
                    <Grid item xs={12}>
                        {isDataLoaded && (
                            <>
                                <Box sx={{
                                    display: 'flex',
                                    position: 'relative',  
                                    width: '100%',
                                    mb: 4
                                }}>
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}>
                                        <Tabs
                                            value={tabValue}
                                            onChange={handleTabChange}
                                            aria-label="courses management tabs"
                                            variant={matchDownSM ? "scrollable" : "standard"}
                                            sx={{ mb: 4 }}
                                        >
                                            <Tab
                                                label={`${matchDownSM ? 'Courses' : 'Outstanding Courses'} (${userOutstandingCourses?.length || 0})`}
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                label={`${matchDownSM ? 'Completed' : 'Completed Courses'} (${fetchedUserCompletedCourses?.length || 0})`}
                                                {...a11yProps(1)}
                                            />
                                        </Tabs>
                                    </Box>
                                    <Box sx={{
                                        position: 'absolute',
                                        right: 2.5,
                                        top: 22.5,
                                        transform: 'translateY(-50%)',
                                        display: 'flex',
                                        gap: 2
                                    }}>
                                        {!(matchDownMD) && (
                                            <>
                                            <FeedbackButton marginRight={2} />
                                            <FeedbackButton support={true} />
                                            </>
                                        )}
                                    </Box>
                                </Box>

                                <TabPanel value={tabValue} index={0}>
                                    <CourseCarousel
                                        showCourseContent={showCourseContent}
                                        courses={userOutstandingCourses}
                                        loading={userOutstandingCoursesLoading || userCompletedCoursesLoading}
                                        error={userOutstandingCoursesError}
                                        type={"outstanding"}
                                    />
                                </TabPanel>

                                <TabPanel value={tabValue} index={1}>
                                    <CourseCarousel
                                        showCourseContent={showCourseContent}
                                        courses={fetchedUserCompletedCourses}
                                        loading={userOutstandingCoursesLoading || userCompletedCoursesLoading}
                                        error={userCompletedCoursesError}
                                        type={"completed"}
                                    />
                                </TabPanel>
                            </>
                        )}
                    </Grid>
                </MainCard>
            </>
        );
    }
}
