import React, { useState } from 'react';
import { Box, Typography, Divider, Grid, useMediaQuery, IconButton, Modal } from "@mui/material";
import { motion, AnimatePresence } from 'framer-motion';
import ContentPackCardComponent from '../../components/cards/ContentPackCard';
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { ReactComponent as CourseIcon } from "assets/navigation-icons/course.svg";
import { useGetContentPackByIdQuery, useGetValidCoursesQuery } from '../../store/services/university-service';
import ContentPackCoursePreviewModal from './ContentPackCoursePreviewModal';
import { useSelector } from 'react-redux';
import { APP_URL } from '../../config';
import { getCardColor } from "../../utils/colorUtils";
import { useModal } from '../../components/Modal/ModalContext';

const DEFAULT_GIF = '/default.gif';
const DEFAULT_IMG = '/default.png';

const containerVariants = {
    hidden: { opacity: 0 }, 
    visible: { opacity: 1, transition: { duration: 0.35 } },
    exit: { opacity: 0, transition: { duration: 0.35 } }
};

const cardVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'spring',
            stiffness: 100,
            damping: 15,
            duration: 0.35
        }
    }
};

const ContentPackPreview = ({ contentPackId, setCoursePreviewOpen }) => {
    const { activeModal, setActiveModal } = useModal();
    const theme = useTheme();
    const [page] = useState(1);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const { tenancyId } = useSelector((state) => state.tenancy);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const lightMode = theme.palette.mode === "light";


    const {
        data: contentPack,
        isLoading: contentPackLoading,
        isError: contentPackError
    } = useGetContentPackByIdQuery(contentPackId);

    const {
        data: coursesData,
        isLoading: coursesLoading,
        error: coursesError
    } = useGetValidCoursesQuery({
        perPage: 10000,
        page: 1,
        includes: '',
        query: '',
        additionalQueryParams: `&published=true&tenancyId=${tenancyId}`
    });

    const handleCoursePreview = (courseId) => {
        setSelectedCourseId(courseId);
        setActiveModal('course');
        setCoursePreviewOpen(true);
    };

    const handleClosePreview = () => {
        setSelectedCourseId(null);
        setActiveModal(null);
        setCoursePreviewOpen(false);
    };

    const loading = contentPackLoading || coursesLoading;
    const error = contentPackError || coursesError;

    if (loading) {
        return (
            <Box p={2}>
                <Typography>Loading content pack...</Typography>
            </Box>
        );
    }

    if (error || !contentPack) {
        return (
            <Box p={2}>
                <Typography color="error">Content pack not found</Typography>
            </Box>
        );
    }

    const getGridItemSize = () => {
        if (matchDownSM) return 12;
        return 6;
    };

    if (!contentPack || !coursesData) {
        return null;
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box p={2} sx={{ flexShrink: 0 }}>
                <Typography variant="h2" mb={2}>
                    {contentPack.contentPackName}
                </Typography>
                <Divider sx={{ width: "100%", mb:2}} />
            </Box>

            <Box p={2} pt={0} sx={{
                overflowY: 'auto',
                flexGrow: 1,
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: theme.palette.background.default,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.divider,
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
            }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={page}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={containerVariants}
                            >
                                <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                                    {contentPack.courseIds.map((courseId) => {
                                        const courseData = coursesData?.find((c) => c.id === courseId);

                                        const course_gif = courseData?.courseContentPath
                                            ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.gif')}`
                                            : DEFAULT_GIF;
                                        const course_img = courseData?.courseContentPath
                                            ? `${APP_URL}/${courseData.courseContentPath.replace('index.html', 'card.png')}`
                                            : DEFAULT_IMG;

                                        return (
                                            <Grid item xs={12} sm={getGridItemSize()} key={courseId} sx={{ mb: 4 }}>
                                                <motion.div
                                                    variants={cardVariants}
                                                    initial="hidden"
                                                    animate="visible"
                                                    whileHover={{ scale: 1.03, transition: { duration: 0.4 } }}
                                                    style={{ height: '100%' }}
                                                >
                                                    <ContentPackCardComponent
                                                        image={course_img}
                                                        title={courseData?.courseName ? courseData.courseName : "undefined"}
                                                        gif={course_gif}
                                                        type="preview"
                                                        isPreview={true}
                                                        onClick={() => handleCoursePreview(courseId)}
                                                    />
                                                </motion.div>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </motion.div>
                        </AnimatePresence>
                    </Grid>
                </Grid>

                {contentPack.courseIds.length === 0 && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 4
                    }}>
                        <Typography color="textSecondary">
                            No courses in this content pack
                        </Typography>
                    </Box>
                )}
            </Box>

                {selectedCourseId && (
                    <ContentPackCoursePreviewModal
                        courseId={selectedCourseId}
                        open={activeModal === 'course'}
                        onClose={handleClosePreview}
                    />
                )}
            </Box>
    );
};

ContentPackPreview.propTypes = {
    contentPackId: PropTypes.string.isRequired
};

export default ContentPackPreview;