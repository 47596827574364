import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BlockingModal = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: 'rgba(0, 0, 0, 0.7)',
                zIndex: 9999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    bgcolor: 'background.default',
                    borderRadius: 2,
                    p: 4,
                    maxWidth: 600,
                    textAlign: 'left'
                }}
            >
                <Typography variant="h4" component="h4" mb={2}>
                    Content Packs Unavailable
                </Typography>
                <Divider sx={{ marginRight: 2, marginLeft: 2, mb: 2 }} />
                <Typography variant="body1" mb={4}>
                    Content packs are currently unavailable while 'One-click courses' is enabled.
                    Please contact your account manager to have one-click courses disabled before proceeding.
                </Typography>
                <Typography variant="body1" mb={4} color="grey">
                    Content packs are a set of specified courses sent to new users added to the portal.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(-1)}
                >
                    Go Back
                </Button>
            </Box>
        </Box>
    );
};

export default BlockingModal;