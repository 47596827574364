import { useState, useCallback, useEffect } from "react";
import {
    Box,
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Stack,
    CircularProgress
} from "@mui/material";
import { useSelector } from "react-redux";
import { useCreateOnDemandMutation } from "../../store/services/tenancy.service";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import MainCard from "../../components/cards/MainCard";
import CustomStepIcon from "../../components/Icon/CustomStepIcon";
import ColorLibConnector from "../../components/ColorLibConnector";
import ContentSelection from "./CreateOnDemand/ContentSelection";
import DeliverySection from "./CreateOnDemand/DeliverySelection";
import RecipientSelection from "./CreateOnDemand/RecipientSelection";
import ReviewAndEdit from "./CreateOnDemand/ReviewAndEdit";
import PropTypes from "prop-types";

const STEPS = ["Content", "Recipients", "Schedule", "Review"];

// Helper function to get formatted time
const getFormattedTime = (date) => {
    return date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    });
};

const INITIAL_STATE = {
    simsAndCoursesToSend: [],
    delivery: "sendNow",
    scheduledDate: new Date(Date.now() + 5 * 60000),
    startDate: new Date(Date.now() + 5 * 60000),
    startTime: '',
    endDate: new Date(Date.now() + 10 * 60000),
    endTime: '',
    pauseOnWeekends: false,
    recipients: "Tenancy",
    recipientsId: []
};

// Helper function to combine date and time
const combineDateAndTime = (date, time) => {
    if (!date || !time) return null;
    const [hours, minutes] = time.split(':');
    const newDate = new Date(date);
    newDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    return newDate;
};

export default function OnDemandStepper({ setAddContent, format }) {
    const { tenancyId, tenancyName } = useSelector((state) => state.tenancy);
    const [createOnDemand] = useCreateOnDemandMutation();

    const [activeStep, setActiveStep] = useState(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(INITIAL_STATE);

    const handleChange = useCallback((name, value) => {
        setState((prevState) => ({ ...prevState, [name]: value }));
    }, []);

    const handleNext = useCallback(() => {
        setActiveStep((prevStep) => prevStep + 1);
    }, []);

    const handleReset = useCallback(() => {
        setActiveStep(0);
        setSuccess(false);
        setState(INITIAL_STATE);
    }, []);

    const validateScheduledDate = useCallback(() => {
        if (state.delivery !== "schedule") return true;
        return state.scheduledDate && state.scheduledDate > new Date();
    }, [state.delivery, state.scheduledDate]);

    const validateBatchSend = useCallback(() => {
        const startDateTime = combineDateAndTime(state.startDate, state.startTime);
        const endDateTime = combineDateAndTime(state.endDate, state.endTime);

        if (!startDateTime || !endDateTime) return false;
        if (startDateTime >= endDateTime) return false;

        return startDateTime > new Date() &&
            (endDateTime.getTime() - startDateTime.getTime()) >= 10800000;
    }, [state.endDate, state.endTime, state.startDate, state.startTime]);

    const isNextDisabled = useCallback(() => {
        if (loading) return true;
        if (activeStep === 0) {
            return state.simsAndCoursesToSend.length === 0;
        } else if (activeStep === 1) {
            return !state.recipients || (state.recipients !== "Tenancy" && state.recipientsId.length === 0);
        } else if (activeStep === 2) {
            return !state.delivery ||
                (state.delivery === "schedule" && !validateScheduledDate()) ||
                (state.delivery === "batchSend" && !validateBatchSend());
        } else if (activeStep === 3) {
            return false;
        }
    }, [activeStep, loading, state.delivery, state.recipients, state.recipientsId, state.simsAndCoursesToSend.length, validateBatchSend, validateScheduledDate]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, recipientsId: [] }));
    }, [state.recipients]);

    const handleLabelClick = useCallback((index) => () => {
        if (index < activeStep) setActiveStep(index);
    }, [activeStep]);

    const handleSend = useCallback(async () => {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        setLoading(true);

        const createData = (contentId, recipient, recipientEmail, recipientId) => {
            let scheduleDateTime;
            let endDateTime;

            if (state.delivery === "sendNow") {
                // For sendNow, use current time plus 5 minutes for start
                scheduleDateTime = new Date(Date.now() + 5 * 60000);
                // For sendNow, use start time plus another 5 minutes for end
                endDateTime = new Date(Date.now() + 10 * 60000);
            } else if (state.delivery === "schedule") {
                scheduleDateTime = state.scheduledDate;
                endDateTime = state.scheduledDate;
            } else {
                // batchSend case - combine date and time using the existing combineDateAndTime helper
                scheduleDateTime = combineDateAndTime(state.startDate, state.startTime);
                endDateTime = combineDateAndTime(state.endDate, state.endTime);

                if (!scheduleDateTime || !endDateTime) {
                    throw new Error('Invalid date/time combination for batch send');
                }
            }

            return {
                TenancyId: tenancyId,
                ScheduleOption: state.delivery,
                ScheduleEmailDateTime: scheduleDateTime,
                SendType: format,
                SendTypeName: contentId,
                SimulationId: contentId,
                SendTo: state.recipients,
                SendToName: recipientEmail || "",
                SendToId: recipientId,
                SendToUserId: recipient === "User" ? recipient.id : "",
                ScheduleDateTimeEnd: endDateTime,
                SendingTimeStart: state.startTime || null,
                SendingTimeEnd: state.endTime || null,
                PauseWeekends: state.pauseOnWeekends || false,
                StopSending: false,
            };
        };

        const sendData = async (data) => {
            try {
                await createOnDemand({ data, tenancyId }).unwrap();
                await delay(2000);
            } catch (error) {
                console.error(`Error sending for formatId ${data.SimulationId}:`, error);
                setError(true);
                setLoading(false);
                setTimeout(() => setError(false), 5000);
                return;
            }
        };

        let tasks = [];

        if (state.recipients === "User") {
            state.simsAndCoursesToSend.forEach((content) => {
                state.recipientsId.forEach((user) => {
                    const data = createData(content.id, "User", user.email, 0);
                    tasks.push(sendData(data));
                });
            });
        } else if (state.recipients === "Tenancy") {
            state.simsAndCoursesToSend.forEach((content) => {
                const data = createData(content.id, "Tenancy", "", tenancyId);
                tasks.push(sendData(data));
            });
        } else {
            const recipientId = state.recipientsId.map((item) =>
                state.recipients === "Group" ? item.userGroupId : item.id
            );

            state.simsAndCoursesToSend.forEach((content) => {
                recipientId.forEach((id) => {
                    const data = createData(content.id, state.recipients, "", id);
                    tasks.push(sendData(data));
                });
            });
        }

        await Promise.all(tasks);

        setSuccess(true);
        setLoading(false);
    }, [createOnDemand, state, tenancyId, format]);

    const handleFinish = useCallback(() => {
        setAddContent(false);
    }, [setAddContent]);

    const stepComponents = [
        <ContentSelection
            format={format}
            simsAndCoursesToSend={state.simsAndCoursesToSend}
            setSimsAndCoursesToSend={(id) => handleChange("simsAndCoursesToSend", id)}
            onNextStep={handleNext}
        />,
        <RecipientSelection
            multipleCourses={state.simsAndCoursesToSend.length > 1}
            recipient={state.recipients}
            recipientId={state.recipientsId}
            handleRecipientChange={(e, value) => handleChange("recipients", value)}
            setRecipientId={(id) => handleChange("recipientsId", id)}
        />,
        <DeliverySection
            format={format}
            delivery={state.delivery}
            handleDeliveryChange={(e, value) => handleChange("delivery", value)}
            scheduledDate={state.scheduledDate}
            setScheduledDate={(date) => handleChange("scheduledDate", date)}
            startDate={state.startDate}
            setStartDate={(date) => handleChange("startDate", date)}
            startTime={state.startTime}
            setStartTime={(time) => handleChange("startTime", time)}
            endDate={state.endDate}
            setEndDate={(date) => handleChange("endDate", date)}
            endTime={state.endTime}
            setEndTime={(time) => handleChange("endTime", time)}
            pauseOnWeekends={state.pauseOnWeekends}
            setPauseOnWeekends={(pause) => handleChange("pauseOnWeekends", pause)}
        />,
        <ReviewAndEdit
            simsAndCoursesToSend={state.simsAndCoursesToSend}
            format={format}
            delivery={state.delivery}
            scheduledDate={state.scheduledDate}
            startDate={combineDateAndTime(state.startDate, state.startTime)}
            endDate={combineDateAndTime(state.endDate, state.endTime)}
            pauseOnWeekends={state.pauseOnWeekends}
            recipients={state.recipients}
            recipientsId={state.recipientsId}
            handleCardClick={handleLabelClick}
        />
    ];

    if (success) {
        return (
            <MainCard content={true} sx={{ width: "100%", overflow: "hidden", display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 250px)', bgcolor: 'background.default' }} border={false} boxShadow={false}>
                <Stack direction="column" spacing={4} alignItems="center" sx={{ textAlign: 'center' }}>
                    <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 60 }} />
                    <Typography variant="h4" sx={{ mt: 2 }}>
                        On-demand {state.delivery === "sendNow" ? "sent" : "scheduled"} successfully!
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        {state.delivery === "schedule"
                            ? `Scheduled date: ${state.scheduledDate?.toLocaleString()}`
                            : state.delivery === "batchSend"
                                ? `Batch send period: ${combineDateAndTime(state.startDate, state.startTime)?.toLocaleDateString()} to ${combineDateAndTime(state.endDate, state.endTime)?.toLocaleDateString()}${state.pauseOnWeekends ? ' (Pausing on weekends)' : ''}`
                                : ''}
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 4, width: '100%' }}>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleReset}
                            sx={{ width: '50%' }}
                        >
                            Create Another On-demand
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFinish}
                            sx={{ ml: 2, width: '50%' }}
                        >
                            Finish
                        </Button>
                    </Stack>
                </Stack>
            </MainCard>
        );
    }

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", mb: 4 }}>
                <Button
                    variant="outlined"
                    color="black"
                    onClick={() => setAddContent(false)}
                >
                    Cancel
                </Button>
                <Box sx={{ width: "40%" }}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<ColorLibConnector />}>
                        {STEPS.map((label, index) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={(props) =>
                                    <CustomStepIcon {...props} onClick={handleLabelClick(index)} />
                                }>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                <Button
                    color="primary"
                    variant="contained"
                    onClick={activeStep === STEPS.length - 1 ? handleSend : handleNext}
                    disabled={isNextDisabled() || loading}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Confirm Selection"}
                </Button>
            </Box>
            <StepContent
                activeStep={activeStep}
                stepComponents={stepComponents}
            />
        </>
    );
}

function StepContent({ activeStep, stepComponents }) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {stepComponents[activeStep]}
        </Box>
    );
}

OnDemandStepper.propTypes = {
    setAddContent: PropTypes.func.isRequired,
    format: PropTypes.string.isRequired
};

StepContent.propTypes = {
    activeStep: PropTypes.number.isRequired,
    stepComponents: PropTypes.array.isRequired,
};