import { useState } from "react";
import PropTypes from "prop-types";
import { timelineItemClasses } from "@mui/lab";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { Alert, Box, Button, Typography } from "@mui/material";
import parseJourneyDataMessage from "../../utils/ParseJourneyDataMessage";
import Timeline from "@mui/lab/Timeline";
import { ReactComponent as SimulationIcon } from "assets/navigation-icons/simulation.svg";
import { ReactComponent as CourseIcon } from "assets/navigation-icons/course.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

JourneyTimeline.propTypes = {
    journeyData: PropTypes.object.isRequired,
    handleDeleteOption: PropTypes.func.isRequired
};

export default function JourneyTimeline(props) {
    const { journeyData, handleDeleteOption } = props;
    const [startDate, setStartDate] = useState(new Date());

    // Create an array of indices along with the sorted options
    const sortedOptionsWithIndices = journeyData.journeyOptions.map((option, originalIndex) => ({
        option,
        originalIndex
    })).sort((a, b) => {
        const monthA = Number(a.option.month);
        const monthB = Number(b.option.month);
        const dayA = Number(a.option.day);
        const dayB = Number(b.option.day);

        if (monthA !== monthB) {
            return monthA - monthB;
        }

        if (dayA !== dayB) {
            return dayA - dayB;
        }

        return new Date(a.option.dateCreated) - new Date(b.option.dateCreated);
    });

    return (
        <Box sx={{ mt: 5 }}>
            <Typography variant="h4" gutterBottom>
                Journey Timeline
            </Typography>

            {sortedOptionsWithIndices.length > 0 && (
                <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Journey Start Date"
                            value={startDate}
                            onChange={setStartDate}
                            format="dd MMMM yyyy"
                            sx={{ width: "50%", mt: 2 }}
                            minDateTime={new Date()}
                        />

                        <Alert severity="warning" sx={{ mt: 2 }}>
                            Send dates are indicative and based on a journey starting on the above date
                        </Alert>
                    </LocalizationProvider>
                </>
            )}

            {sortedOptionsWithIndices.length === 0 ? (
                <Box sx={{ textAlign: "center", mt: 5 }}>
                    <Typography variant="h6" color="textSecondary">
                        No simulations or courses added yet. Start by adding a simulation or course above.
                    </Typography>
                </Box>
            ) : (
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0
                        }
                    }}
                >
                    {sortedOptionsWithIndices.map(({ option, originalIndex }, index) => {
                        const { message, isSent } = parseJourneyDataMessage(option, startDate);
                        return (
                            <TimelineItem key={originalIndex}>
                                <TimelineSeparator>
                                    <TimelineDot
                                        color={option.type === 1 ? "primary" : "secondary"}
                                        variant={isSent ? "filled" : "outlined"}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "40px",
                                            height: "40px"
                                        }}
                                    >
                                        {option.type === 1 ? <CourseIcon width="20px" height="20px" /> :
                                            <SimulationIcon width="20px" height="20px" />}
                                    </TimelineDot>
                                    {index < sortedOptionsWithIndices.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: "12px", px: 2 }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Typography variant="h6">
                                                {option.type === 0 ? "Simulation" : "Course"}: {option.typeName || option.typeId} will be
                                                sent on Journey Day {option.day} of Month {option.month}
                                            </Typography>
                                            <Typography variant="caption">
                                                {message}
                                            </Typography>
                                        </Box>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleDeleteOption(originalIndex)}
                                        >
                                            Remove
                                        </Button>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
                </Timeline>
            )}
        </Box>
    );
}