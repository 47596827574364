import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import {
    List, ListItem, ListItemText, ListItemAvatar, Avatar, ToggleButtonGroup,
    ToggleButton, Box, Typography, TextField, Button, Grid, Paper,
    InputAdornment, CircularProgress, Dialog, DialogContent, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import ContentPackCardComponent from '../../components/cards/ContentPackCard';
import { useGetCoursesQuery, useGetCourseByIdQuery } from "../../store/services/university-service";
import { APP_URL } from '../../config';
import { Portal } from '@mui/material';
import { getCardColor, getTextColor } from "../../utils/colorUtils";
import ContentPackCoursePreviewModal from './ContentPackCoursePreviewModal';
import { ModalProvider } from '../../components/Modal/ModalContext';


const TabContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: -43,
    left: 0,
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: -1,
}));

const PreviewTab = styled(Box)(({ theme, index = 0 }) => ({
    width: '8vw',
    height: '50px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    boxShadow: `
        0 5px 5px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'undefined'},
        5px 2px 5px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'undefined'}
    `,
    transform: `translateX(${index * -15}%)`,
    zIndex: 3 - index,
    '& .label-text': {
        transform: index === 0 ? 'none' : `translateX(${7.2}%)`,
        width: '100%',
        textAlign: 'center',
        color: theme.palette.mode === 'light' ? '#000' : '#000',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 8px'
    }
}));

const ShadowOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: -10,
    left: 0,
    right: 0,
    height: 15,
    borderRadius: 10,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.08) 40%, transparent 100%)',
    zIndex: 0,
    pointerEvents: 'none',
}));

const CoursePreviewModal = ({ courseId, open, onClose }) => {
    const theme = useTheme();
    const { data: courseData, isLoading, error } = useGetCourseByIdQuery({
        courseId,
        includes: ''
    });

    const courseURL = courseData?.courseContentPath
        ? `${APP_URL}/${courseData.courseContentPath}`
        : '';

    return (
        <Box sx={{ position: 'relative' }}>
            {open && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: '-50px',
                        top: '0px',
                        zIndex: (theme) => theme.zIndex.modal + 1,
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        sx={{
                            bgcolor: 'background.paper',
                            boxShadow: 2,
                            '&:hover': {
                                bgcolor: 'background.paper',
                                opacity: 0.9
                            },
                            width: 40,
                            height: 40,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}

            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '35vw',
                        height: '70vh',
                        maxHeight: '70vh',
                        maxWidth: '35vw',
                        margin: '0 auto',
                        backgroundColor: 'background.paper',
                        borderRadius: '10px',
                        overflow: 'visible',
                        position: 'relative',
                        zIndex: 1,
                        isolation: 'isolate',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            inset: 0, 
                            background: theme.palette.background.paper,
                            borderRadius: '10px',
                            zIndex: 1
                        }
                    }
                }}
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    },
                    '& .MuiPaper-root': {
                        m: 0
                    }
                }}
            >
                <DialogContent
                    sx={{
                        p: 0,
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        borderRadius: '10px',
                        position: 'relative',
                        zIndex: 2,
                        backgroundColor: theme.palette.background.paper,
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                    }}
                >
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                        borderRadius: '10px',
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 2
                    }}>
                        {isLoading && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CircularProgress />
                            </Box>
                        )}

                        {error && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                Error loading course preview
                            </Box>
                        )}

                        {courseData && (
                            <iframe
                                src={courseURL}
                                title="Course Preview"
                                width="100%"
                                height="100%"
                                style={{
                                    border: 'none',
                                    borderRadius: '10px'
                                }}
                            />
                        )}
                    </Box>
                </DialogContent>

                <ShadowOverlay />

                <TabContainer>
                    <PreviewTab
                        index={0}
                        sx={{
                            bgcolor: theme.palette.warning.main,
                        }}
                    >
                        <span className="label-text">{courseData?.format}</span>
                    </PreviewTab>
                    <PreviewTab
                        index={1}
                        sx={{
                            bgcolor: theme.palette.primary.main,
                        }}
                    >
                        <span className="label-text">{courseData?.languageCode}</span>
                    </PreviewTab>
                </TabContainer>
            </Dialog>
        </Box>
    );
};

const EmptyStateContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(3),
    textAlign: 'center',
}));

const EmptyStateIcon = styled(Box)(({ theme }) => ({
    color: theme.palette.action.disabled,
    marginBottom: theme.spacing(2),
    '& svg': {
        fontSize: 64,
    },
}));

const DroppableSection = styled(Paper)(({ theme, isDragOver }) => ({
    height: 700,
    minHeight: 700,
    overflowY: 'auto',
    padding: theme.spacing(2),
    backgroundColor: isDragOver ? getCardColor(theme.palette.mode === 'light') : getCardColor(theme.palette.mode === 'light', false),
    border: `1px solid ${isDragOver ? theme.palette.primary.main : theme.palette.divider}`,
    borderRadius: theme.borderRadius.sm,
    transition: 'all 0.2s ease',
    display: 'flex',
    flexDirection: 'column'
}));

const ControlsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const ContentArea = styled(Box)({
    flexGrow: 1,
    overflow: 'auto'
});

const SearchContainer = styled(Box)({
    flexGrow: 1,
});

const DraggableCard = styled(Box)(({ isDragging }) => ({
    opacity: isDragging ? 0.5 : 1,
    cursor: 'move',
    transition: 'all 0.2s ease',
    transform: isDragging ? 'scale(1.02)' : 'scale(1)',
    position: 'relative',
}));

const CourseListItem = styled(ListItem)(({ theme, isDragging }) => ({
    backgroundColor: getCardColor(theme.palette.mode === 'light', false),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    opacity: isDragging ? 0.5 : 1,
    cursor: 'move',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
        backgroundColor: getCardColor(theme.palette.mode === 'light'),
    },
    '& img': {
        pointerEvents: 'none',
    },
}));

const ListItemContent = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
});

const PreviewButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    minWidth: '100px',
}));

const CourseSection = ({
    title,
    courses,
    onDrop,
    sectionId,
    draggedCourse,
    setDraggedCourse,
    isDragOver,
    setIsDragOver,
    showSearch = false,
    onPreview
}) => {
    const theme = useTheme();
    const [viewMode, setViewMode] = useState(sectionId === 'available' ? 'list' : 'grid');
    const isDraggingRef = useRef(false);
    const dragPreviewRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [dragPreview, setDragPreview] = useState({ show: false, x: 0, y: 0 });

    const filteredCourses = courses.filter(course =>
        course.courseName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDragStart = (e, course) => {
        e.stopPropagation();

        const dragImage = document.createElement('div');
        dragImage.style.opacity = '0';
        document.body.appendChild(dragImage);
        e.dataTransfer.setDragImage(dragImage, 0, 0);

        setTimeout(() => document.body.removeChild(dragImage), 0);

        isDraggingRef.current = true;
        setDraggedCourse({ course, sectionId, sourceViewMode: viewMode });
        setDragPreview({ show: true, x: e.clientX, y: e.clientY });

        if (dragPreviewRef.current) {
            cancelAnimationFrame(dragPreviewRef.current);
        }
    };

    const handleDrag = (e) => {
        if (e.clientX === 0 && e.clientY === 0 || !isDraggingRef.current) return;

        if (dragPreviewRef.current) {
            cancelAnimationFrame(dragPreviewRef.current);
        }

        dragPreviewRef.current = requestAnimationFrame(() => {
            if (isDraggingRef.current) {
                setDragPreview({ show: true, x: e.clientX, y: e.clientY });
            }
        });
    };

    const handleDragEnd = () => {
        isDraggingRef.current = false;
        if (dragPreviewRef.current) {
            cancelAnimationFrame(dragPreviewRef.current);
        }
        setDragPreview({ show: false, x: 0, y: 0 });
        setDraggedCourse(null);
        setIsDragOver(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        if (!isDragOver) setIsDragOver(sectionId);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        if (e.currentTarget.contains(e.relatedTarget)) return;
        setIsDragOver(null);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(null);
        if (draggedCourse && draggedCourse.sectionId !== sectionId) {
            onDrop(draggedCourse.course.id, draggedCourse.sectionId, sectionId);
        }
        setDraggedCourse(null);
        setDragPreview({ show: false, x: 0, y: 0 });
    };

    const renderEmptyState = () => {
        if (searchQuery) {
            return (
                <EmptyStateContainer>
                    <EmptyStateIcon><SearchOffIcon /></EmptyStateIcon>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        No Matching Courses Found
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        We couldn't find any courses matching "{searchQuery}"
                    </Typography>
                    <Button variant="text" onClick={() => setSearchQuery('')} sx={{ mt: 2 }}>
                        Clear Search
                    </Button>
                </EmptyStateContainer>
            );
        }

        return (
            <EmptyStateContainer>
                <EmptyStateIcon><FormatListBulletedIcon /></EmptyStateIcon>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                    {sectionId === 'available' ? 'No Available Courses' : 'No Selected Courses'}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    {sectionId === 'available'
                        ? 'There are currently no courses available to add to your content pack.'
                        : 'Start building your content pack by dragging courses from the available section.'}
                </Typography>
            </EmptyStateContainer>
        );
    };

    const renderGridView = () => (
        <Grid container spacing={2}>
            {filteredCourses.map((course) => (
                <Grid item sm={12} md={6} key={course.id}>
                    <DraggableCard
                        draggable
                        onDragStart={(e) => handleDragStart(e, course)}
                        onDrag={handleDrag}
                        onDragEnd={handleDragEnd}
                        isDragging={draggedCourse?.course.id === course.id}
                    >
                        <ContentPackCardComponent
                            image={`${APP_URL}/${course.courseContentPath.replace('index.html', 'card.png')}`}
                            gif={`${APP_URL}/${course.courseContentPath.replace('index.html', 'card.gif')}`}
                            title={course.courseName}
                            type="preview"
                            onClick={() => onPreview(course.id)}
                        />
                    </DraggableCard>
                </Grid>
            ))}
        </Grid>
    );

    const renderListView = () => (
        <List sx={{ width: '100%', bgcolor: 'transparent' }}>
            {filteredCourses.map((course) => (
                <CourseListItem
                    key={course.id}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, course)}
                    onDrag={handleDrag}
                    onDragEnd={handleDragEnd}
                    isDragging={draggedCourse?.course.id === course.id}
                >
                    <ListItemContent>
                        <ListItemAvatar>
                            <Avatar
                                variant="rounded"
                                src={`${APP_URL}/${course.courseContentPath.replace('index.html', 'card.png')}`}
                                alt={course.courseName}
                                sx={{ width: 56, height: 56, pointerEvents: 'none' }}
                                draggable="false"
                            />
                        </ListItemAvatar>
                        <ListItemText primary={course.courseName} sx={{ ml: 2 }} />
                    </ListItemContent>
                    <PreviewButton
                        variant="contained"
                        color={theme.palette.mode === 'light' ? "warning" : "primary"}
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            onPreview(course.id);
                        }}
                        sx={{
                            ...(theme.palette.mode !== 'light' && {
                                bgcolor: theme.palette.primary.main,
                                color: getTextColor(theme.palette.primary.main),
                                '&:hover': {
                                    bgcolor: "#69686b",
                                },
                            }),
                        }}
                    >
                        Preview
                    </PreviewButton>
                </CourseListItem>
            ))}
        </List>
    );

    return (
        <DroppableSection
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            isDragOver={isDragOver === sectionId}
        >
            <Typography variant="h4" sx={{ pb: 2 }}>{title}</Typography>

            <ControlsContainer>
                {showSearch ? (
                    <SearchContainer>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Search courses..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </SearchContainer>
                ) : (
                    <Box flexGrow={1} />
                )}
                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={(e, newView) => newView && setViewMode(newView)}
                    size="small"
                >
                    <ToggleButton value="grid"><GridViewIcon /></ToggleButton>
                    <ToggleButton value="list"><ListIcon /></ToggleButton>
                </ToggleButtonGroup>
            </ControlsContainer>

            <ContentArea>
                {filteredCourses.length === 0
                    ? renderEmptyState()
                    : (viewMode === 'grid' ? renderGridView() : renderListView())
                }
            </ContentArea>

            {isDraggingRef.current && dragPreview.show && draggedCourse && draggedCourse.sectionId === sectionId && (
                <Portal>
                    <Box
                        sx={{
                            position: 'fixed',
                            left: dragPreview.x - 100,
                            top: dragPreview.y - 50,
                            transform: `translate3d(0,0,0) scale(${isDragOver ? '1.02' : '1'})`,
                            opacity: isDragOver ? 0.9 : 0.7,
                            pointerEvents: 'none',
                            zIndex: theme.zIndex.tooltip + 1,
                            willChange: 'transform',
                        }}
                    >
                        <ContentPackCardComponent
                            key={`preview-${draggedCourse.course.id}`}
                            image={`${APP_URL}/${draggedCourse.course.courseContentPath.replace('index.html', 'card.png')}`}
                            gif={`${APP_URL}/${draggedCourse.course.courseContentPath.replace('index.html', 'card.gif')}`}
                            title={draggedCourse.course.courseName}
                            type="preview"
                        />
                    </Box>
                </Portal>
            )}
        </DroppableSection>
    );
};

const ContentPackForm = ({ initialData, onSave }) => {
    const [selectedCourses, setSelectedCourses] = useState(initialData?.courseIds || []);
    const [draggedCourse, setDraggedCourse] = useState(null);
    const [isDragOver, setIsDragOver] = useState(null);
    const [coursePreviewOpen, setCoursePreviewOpen] = useState(false);
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [previewModal, setPreviewModal] = useState({
        open: false,
        courseId: null
    });

    const user = JSON.parse(localStorage.getItem('user'));
    const additionalQueryParams = user?.role === "Administrators"
        ? ""
        : `&published=true&tenancyId=${tenancyId}`;

    const { data: allCourses = [], isLoading } = useGetCoursesQuery({
        perPage: 100,
        page: 0,
        includes: '',
        query: '',
        additionalQueryParams: additionalQueryParams
    });



    const availableCourses = allCourses.filter(
        course => !selectedCourses.includes(course.id)
    );

    const handleDrop = (courseId, sourceType, targetType) => {
        if (sourceType === targetType) return;

        const newSelectedCourses = targetType === 'selected'
            ? [...selectedCourses, courseId]
            : selectedCourses.filter(id => id !== courseId);

        setSelectedCourses(newSelectedCourses);
        onSave({ content: newSelectedCourses });
    };

    const handleCoursePreview = (courseId) => {
        setPreviewModal({
            open: true,
            courseId
        });
        setCoursePreviewOpen(true);
    };

    const handleClosePreview = () => {
        setPreviewModal({
            open: false,
            courseId: null
        });
        setCoursePreviewOpen(false);
    };

    if (isLoading) return <Typography>Loading courses...</Typography>;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <CourseSection
                        title="Available Courses"
                        courses={availableCourses}
                        onDrop={handleDrop}
                        sectionId="available"
                        draggedCourse={draggedCourse}
                        setDraggedCourse={setDraggedCourse}
                        isDragOver={isDragOver}
                        setIsDragOver={setIsDragOver}
                        showSearch={true}
                        onPreview={ handleCoursePreview }
                    />
                </Grid>
                <Grid item xs={6}>
                    <CourseSection
                        title="Content Pack"
                        courses={allCourses.filter(course => selectedCourses.includes(course.id))}
                        onDrop={handleDrop}
                        sectionId="selected"
                        draggedCourse={draggedCourse}
                        setDraggedCourse={setDraggedCourse}
                        isDragOver={isDragOver}
                        setIsDragOver={setIsDragOver}
                        showSearch={false}
                        onPreview={handleCoursePreview}
                    />
                </Grid>
            </Grid>
            <ModalProvider>
            <ContentPackCoursePreviewModal
                    courseId={previewModal.courseId}
                    open={previewModal.open}
                    onClose={handleClosePreview}
                    isStandalone={true}
                />
            </ModalProvider>
        </>
    );
};


ContentPackForm.propTypes = {
    initialData: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    isSaving: PropTypes.bool,
};

export default ContentPackForm;