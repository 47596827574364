import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
    useDeleteAllUsersMutation,
    useDeleteJourneysAndSchedulesMutation,
    useDeleteTenancyMutation,
    useGetReminderOptionsQuery,
    useUpdateReminderOptionsMutation,
    useUpdateTenancyMutation,
    useRefreshReportsMutation
}
    from "../../store/services/tenancy.service";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    Typography,
    Tooltip,
    MenuItem,
    Select,
    FormControl, Switch
} from "@mui/material";
import {
    useGetSchedulerSettingsQuery,
    useUpdateSchedulerSettingsMutation
} from "../../store/services/scheduler.service";

import {
    useGetExcelReportSettingsQuery,
    useSendExcelReportSettingsMutation,
    useSendGenerateExcelReportMutation
} from "../../store/services/report.service";

import HelpIcon from "@mui/icons-material/HelpOutline";
import MainCard from "../../components/cards/MainCard";
import LoadingSwitch from "../../components/LoadingSwitch";

import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
    useGetUniversityCourseSettingsQuery,
    useSaveUniversityCourseSettingsMutation
} from "../../store/services/university-service";
import ConfirmationDialog from "../../utils/confirmationDialog";
import { useSendTenancyReminderEmailMutation } from "../../store/services/response.service";
import FeedbackButton from "../../utils/FeedBackButton";
import { getCardColor } from "../../utils/colorUtils";
import DeleteIcon from "@mui/icons-material/Delete";

TenancySettings.propTypes = {
    data: PropTypes.object
};

export default function TenancySettings(props) {
    const { data } = props;
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";
    const environment = process.env.REACT_APP_BRAND_TYPE === 'censornet';
    const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME;
    const navigate = useNavigate();
    const [tenancyData, setTenancyData] = useState(data);
    const [isAdmin, setIsAdmin] = useState(false);

    // Settings state variables
    const [validHeaderKey, setValidHeaderKey] = useState(1);
    const [validHeaderValue, setValidHeaderValue] = useState(1);
    const [reminderOptions, setReminderOptions] = useState({
        alternateUserId: null,
        dateCreated: "1970-01-01T00:00:00.000000",
        dateUpdated: "1970-01-01T00:00:00.000000",
        dateSent: "1970-01-01T00:00:00.000000",
        id: 0,
        userId: "",
        uniqueIdentifier: "",
        tenancyId: tenancyData.tenancyId,
        daysToRemindAfter: 0,
        reminderFrequency: 0
    });
    const [updatingSchedulerSettings, setUpdatingSchedulerSettings] = useState(false);
    const [emailHeaderKeyError, setEmailHeaderKeyError] = useState("");
    const [emailHeaderValueError, setEmailHeaderValueError] = useState("");

    //Button loading states
    const [isDeletingJourney, setIsDeletingJourney] = useState(false);
    const [isDeletingUsers, setIsDeletingUsers] = useState(false);
    const [isDeletingTenancy, setIsDeletingTenancy] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isSendingReminders, setIsSendingReminders] = useState(false);
    const [isSavingReminders, setIsSavingReminders] = useState(false);

    // Switch loading states
    const [loadingStates, setLoadingStates] = useState({
        enableCourseRetake: false,
        allowUnauthorisedTakeCourse: false,
        automaticCourseAssignmentDays: false,
        enableReporting: false,
        anonymiseReports: false,
        enableOndemand: false
    });

    // Confirmation Dialog
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContent, setDialogContent] = useState("");
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogCancelAction, setDialogCancelAction] = useState(null);

    // Snackbar
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    // Mutations
    const [updateTenancy] = useUpdateTenancyMutation();
    const [updateUniversityCourseSettings] = useSaveUniversityCourseSettingsMutation();
    const [deleteAllUsers] = useDeleteAllUsersMutation();
    const [deleteTenancy] = useDeleteTenancyMutation();
    const [deleteJourneysSchedules] = useDeleteJourneysAndSchedulesMutation();
    const [updateSchedulerSettings] = useUpdateSchedulerSettingsMutation();
    const [sendTenancyReminderEmail] = useSendTenancyReminderEmailMutation();
    const [updateReminderOptions] = useUpdateReminderOptionsMutation();
    const [refreshReports] = useRefreshReportsMutation();

    // Emails
    const [currentEmail, setCurrentEmail] = useState('');
    const [emails, setEmails] = useState([]);

    const {
        data: excelReportSettings,
        error: excelReportError,
        isLoading: excelReportLoading,
        refetch: refetchExcelSettings
    } = useGetExcelReportSettingsQuery({ tenancyId: tenancyData.tenancyId });
    const [sendExcelSettings] = useSendExcelReportSettingsMutation();
    const [isSavingExcelSettings, setIsSavingExcelSettings] = useState(false);
    const [sendGenerateReport, { isLoading: isGeneratingReport }] = useSendGenerateExcelReportMutation();

    // Queries 
    const {
        data: schedulerSettings,
        error: schedulerSettingsError,
        isLoading: schedulerSettingsLoading
    } = useGetSchedulerSettingsQuery({ tenancyId: tenancyData.tenancyId });

    const {
        data: tenancyReminderData,
        isLoading: tenancyReminderLoading,
        error: tenancyReminderError
    } = useGetReminderOptionsQuery({ tenancyId: tenancyData.tenancyId });

    const {
        data: universitySettings,
        error: universitySettingsError,
        isLoading: universitySettingsLoading,
        refetch: refetchUniversitySettings
    } = useGetUniversityCourseSettingsQuery({ tenancyId: tenancyData.tenancyId });

    const [unauthSendingBox, setUnauthSendingBox] = useState(universitySettings ? universitySettings?.allowUnauthorisedTakeCourse : false);

    useEffect(() => {
        if (tenancyReminderData)
            setReminderOptions(tenancyReminderData);
    }, [tenancyReminderData]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);

    useEffect(() => {
        if (universitySettings) {
            setTenancyData(prev => ({
                ...prev,
                allowUnauthorisedTakeCourse: universitySettings.allowUnauthorisedTakeCourse
            }));
        }
    }, [universitySettings]);

    useEffect(() => {
        if (excelReportSettings?.emails) {
            setEmails(excelReportSettings.emails);
        }
    }, [excelReportSettings]);

    const handleSaveExcelSettings = async () => {
        setIsSavingExcelSettings(true);
        try {
            await sendExcelSettings({
                tenancyId: tenancyData.tenancyId,
                data: {
                    tenancyId: tenancyData.tenancyId,
                    emails: emails
                }
            }).unwrap();

            setSnackbarMessage("Excel report settings saved successfully");
            setSnackbarSeverity("success");
            await refetchExcelSettings();
        } catch (error) {
            setSnackbarMessage("Error saving Excel report settings");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setIsSavingExcelSettings(false);
        }
    };

    const handleSave = async (updatedData) => {
        if (validHeaderValue !== 1 || validHeaderKey !== 1) {
            setSnackbarMessage("Invalid email header key or value");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return Promise.reject();
        } else if (updatedData.userLicenses < 0 || updatedData.userLicenses < updatedData.adminCount + updatedData.userCount) {
            setSnackbarMessage("Invalid number of user licenses");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return Promise.reject();
        } else {
            try {

                await updateTenancy({
                    data: updatedData,
                    tenancyId: updatedData.tenancyId
                }).unwrap();

                setSnackbarMessage("Settings updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            } catch (error) {
                console.log("Error updating tenancy settings:", error);
                setSnackbarMessage("Error updating tenancy settings.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                throw error;
            }
        }
    }; 
    

    const setLoadingFor = (setting, isLoading) => {
        setLoadingStates(prev => ({
            ...prev,
            [setting]: isLoading
        }));
    };

    const isAnySwitchLoading = (loadingStates) => {
        return Object.values(loadingStates).some(state => state === true);
    };

    // statement is to handle any legacy data and change it to either "-1" or "0" where -1 = false and 0 = true
    const isAutoCourseAssignmentEnabled = (value) => value !== "-1" && value !== null;

    const handleSwitchChange = async (field) => {
        setLoadingFor(field, true);

        const newValue = field === 'automaticCourseAssignmentDays'
            ? (isAutoCourseAssignmentEnabled(tenancyData[field]) ? "-1" : "0")
            : !tenancyData[field];

        const updatedData = {
            ...tenancyData,
            [field]: newValue
        };

        try {
            await updateTenancy({
                data: updatedData,
                tenancyId: updatedData.tenancyId
            }).unwrap();

            // Only update state after successful API call
            setTenancyData(updatedData);
            setSnackbarMessage("Settings updated successfully.");
            setSnackbarSeverity("success");
        } catch (error) {
            console.log("Error updating tenancy settings:", error);
            setSnackbarMessage("Error updating tenancy settings.");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setLoadingFor(field, false);
        }
    };

    const handleSendReminders = async () => {
        setDialogTitle("Send Reminders");
        setDialogContent("Are you sure you want to send reminders?");
        setDialogAction(() => async () => {
            setIsSendingReminders(true);
            await sendTenancyReminderEmail({ tenancyId: tenancyData.tenancyId }).unwrap().then(() => {
                setSnackbarMessage("Reminders sent successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            }).catch(() => {
                setSnackbarMessage("Error sending reminders.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
            setIsSendingReminders(false);
        });
        setDialogOpen(true);
    };


    const handleSaveReminderOptions = () => {
        setIsSavingReminders(true);
        updateReminderOptions({ data: reminderOptions })
        .unwrap()
        .then(() => {
            setSnackbarMessage("Reminder options saved successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        })
        .catch(() => {
            setSnackbarMessage("Error updating reminder options");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        })
        .finally(() => {
            setIsSavingReminders(false);
        });
    };

    const handleRefreshReports = async () => {
        setIsRefreshing(true);
        setDialogTitle("Refresh Reports");
        setDialogContent("Are you sure you want to refresh the reports? This may take a few moments.");
        setDialogCancelAction(() => () => setIsRefreshing(false));
        setDialogAction(() => async () => {
            try {
                await refreshReports(tenancyData.tenancyId).unwrap();
                setSnackbarMessage("Reports refreshed successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            } catch (error) {
                setSnackbarMessage(`Error refreshing reports: ${error.message || 'Unknown error'}`);
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            } finally {
                setIsRefreshing(false);
            }
        });
        setDialogOpen(true);
    };


    const handleSaveUniversityCourseSettings = () => {
        updateUniversityCourseSettings({
            tenancyId: tenancyData.tenancyId,
            settingsData: { tenancyId: tenancyData.tenancyId, allowUnauthorisedTakeCourse: unauthSendingBox }
        })
            .then(() => {
                setSnackbarMessage("Tenancy settings updated successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.log("Error updating Tenancy settings:", error);
                setSnackbarMessage("Error updating Tenancy settings.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handleEmailHeaderChange = (type, value) => {
        switch (type) {
            case "key":
                setTenancyData({ ...tenancyData, customEmailHeaderKey: value });
                // Check for X-BPSAT
                if (value.toUpperCase() === "X-BPSAT") {
                    setValidHeaderKey(-1);
                    setEmailHeaderKeyError("Default email header key cannot be used");
                } else if (/^[a-zA-Z0-9-_]+$/.test(value)) {
                    setValidHeaderKey(1);
                    setEmailHeaderKeyError("");
                } else {
                    setValidHeaderKey(-1);
                    setEmailHeaderKeyError("Key must only contain letters, numbers, hyphens, and underscores");
                }
                return;

            case "value":
                setTenancyData({ ...tenancyData, customEmailHeaderValue: value });
                if (value.toUpperCase() === "66Y7U72LTS") {
                    setValidHeaderValue(-1);
                    setEmailHeaderValueError("Default email header value cannot be used");
                } else if (/^[a-zA-Z0-9 _:;.,\/"'?!(){}[\]@<>=\-+*#$&`|~^%]+$/.test(value)) {
                    setValidHeaderValue(1);
                    setEmailHeaderValueError("");
                } else {
                    setValidHeaderValue(-1);
                    setEmailHeaderValueError("Value contains invalid characters");
                }
                return;
        }
    };

    const handleDeleteJourneysSchedules = () => {
        setIsDeletingJourney(true)
        setDialogTitle("Delete Active Journey");
        setDialogContent("Are you sure you want to delete the active journey? This action is irreversible.");
        setDialogCancelAction(() => () => setIsDeletingJourney(false));
        setDialogAction(() => () => {
            deleteJourneysSchedules({ tenancyId: tenancyData.tenancyId }).unwrap().then((response) => {
                setSnackbarMessage("Journey deleted successfully");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setIsDeletingJourney(false)
            }).catch((error) => {
                setSnackbarMessage("Error deleting journey");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                console.log("Error:", error);
                setIsDeletingJourney(false)
            });
        });
        setDialogOpen(true);
    };

    const handleDeleteUsers = () => {
        setIsDeletingUsers(true);
        setDialogTitle("Delete All Users");
        setDialogContent("Are you sure you want to delete all users? This action is irreversible.");
        setDialogCancelAction(() => () => setIsDeletingUsers(false));
        setDialogAction(() => () => {
            deleteAllUsers({ tenancyId: tenancyData.tenancyId }).unwrap().then((response) => {
                setSnackbarMessage("Users deleted successfully");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setIsDeletingUsers(false);
            }).catch((error) => {
                setSnackbarMessage("Error deleting users request");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                console.log("Error:", error);
                setIsDeletingUsers(false);
            });
        });
        setDialogOpen(true);
    };

    const handleDeleteTenancy = () => {
        setIsDeletingTenancy(true)
        setDialogTitle("Delete Tenancy");
        setDialogContent("Are you sure you want to delete this tenancy? This action is irreversible.");
        setDialogCancelAction(() => () => setIsDeletingTenancy(false));
        setDialogAction(() => () => {
            deleteTenancy({ tenancyId: tenancyData.tenancyId }).unwrap().then((response) => {
                setSnackbarMessage("Tenancy deleted successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setIsDeletingTenancy(false);
                navigate(-1);
            }).catch((error) => {
                setSnackbarMessage("Error deleting tenancy.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                console.log("Error deleting tenancy:", error);
                setIsDeletingTenancy(false);
            });
        });
        setDialogOpen(true);
    };

    const handleOneClickCoursesToggle = async () => {
        setLoadingFor('allowUnauthorisedTakeCourse', true);
        try {
            const newValue = !tenancyData.allowUnauthorisedTakeCourse;
            await updateUniversityCourseSettings({
                tenancyId: tenancyData.tenancyId,
                settingsData: {
                    tenancyId: tenancyData.tenancyId,
                    allowUnauthorisedTakeCourse: newValue
                }
            }).unwrap();
            await refetchUniversitySettings();

            // Only update state after successful API call
            setTenancyData(prev => ({
                ...prev,
                allowUnauthorisedTakeCourse: newValue
            }));
            setSnackbarMessage("One-click courses setting updated successfully.");
            setSnackbarSeverity("success");
        } catch (error) {
            console.error("Error updating one-click courses setting:", error);
            setSnackbarMessage("Error updating one-click courses setting.");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
            setLoadingFor('allowUnauthorisedTakeCourse', false);
        }
    };


    const handleUpdateSchedulerSettings = async () => {
        const data = {
            pauseSending: !schedulerSettings.pauseSending,
            tenancyId: schedulerSettings.tenancyId,
            currentJourney: schedulerSettings.currentJourney
        };
        setUpdatingSchedulerSettings(true);
        setDialogTitle((data.pauseSending ? "Pause" : "Resume") + " Journeys?");
        setDialogContent("Are you sure you want to " + (data.pauseSending ? "pause" : "resume") + " journeys?");
        setDialogCancelAction(() => () => setUpdatingSchedulerSettings(false));
        setDialogAction(() => async () => {
            try { 
                const response = await updateSchedulerSettings({
                    tenancyId: tenancyData.tenancyId,
                    data: data
                }).unwrap();
                setSnackbarMessage((data.pauseSending ? "paused" : "resumed") + " journeys successfully.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            } catch (error) {
                setSnackbarMessage("Error " + (data.pauseSending ? "pausing" : "resuming") + " journeys");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                console.log("Error updating journey settings:", error);
            } finally {
                setUpdatingSchedulerSettings(false);
            }
        });
        setDialogOpen(true);
    };

    const handleSaveUserLicenses = () => {
        handleSave(tenancyData);
    };

    const handleAddEmail = () => {
        if (currentEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentEmail)) {
            setEmails([...emails, currentEmail]);
            setCurrentEmail('');
        } else {
            setSnackbarMessage("Please enter a valid email address");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleRemoveEmail = (emailToRemove) => {
        setEmails(emails.filter(email => email !== emailToRemove));
    };

    const handleGenerateReport = async () => {
        try {
            await sendGenerateReport({
                tenancyId: tenancyData.tenancyId
            }).unwrap();
            setSnackbarMessage("Excel report generation initiated successfully");
            setSnackbarSeverity("success");
        } catch (error) {
            setSnackbarMessage("Error generating Excel report");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    // Render component
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Typography variant="h2" mr={ 4 }>{tenancyData.name}</Typography>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2
                        }}>
                            <FeedbackButton />
                            <Button
                                size="medium"
                                variant="contained"
                                color={schedulerSettings?.pauseSending ? "error" : "primary"}
                                onClick={handleUpdateSchedulerSettings}
                                disabled={schedulerSettingsLoading || schedulerSettingsError || !schedulerSettings}
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                {updatingSchedulerSettings ? <CircularProgress size={24} color="inherit" /> : (schedulerSettings?.pauseSending ? "Journeys Paused" : "Pause Journeys")}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ width: "100%" }} />
                </Grid>

                {/* Courses Section */}
                <Grid item xs={12}>
                    {isAdmin && (
                        <Typography variant="h4" sx={{ ml: 1, mb: 2 }}>Courses</Typography>)}
                    <Grid container spacing={2}>
                        {/* Course retaking */}
                        {isAdmin && (
                            <Grid item xs={12} md={6} lg={4}>
                                <MainCard content={true} boxShadow={true} border={false}
                                    sx={{
                                        height: "100%",
                                        bgcolor: getCardColor(lightMode, tenancyData.enableCourseRetake) //courseRetakeBox ? (lightMode ? "primary.white" : "grey.600") : (lightMode ? "grey.200" : "#3c3e40")
                                    }}>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "space-between"
                                    }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title="Allow users to retake a course if they scored below 80%. Enabling this will apply the settings to all existing courses, including those already assigned">
                                                <IconButton>
                                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="h5">
                                                Course Retaking
                                            </Typography>
                                        </Box>
                                        <LoadingSwitch
                                            checked={tenancyData.enableCourseRetake}
                                            onChange={() => handleSwitchChange('enableCourseRetake')}
                                            loading={loadingStates.enableCourseRetake}
                                            disabled={isAnySwitchLoading(loadingStates)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    </Box>
                                </MainCard>
                            </Grid>)}

                        {/* Course by email NOT IMPLEMENTED YET (REMOVE "false &&" when implemented) */}

                        {isAdmin && (
                            <Grid item xs={12} md={6} lg={4}>
                                {universitySettingsLoading ? (
                                    <MainCard content={true} boxShadow={true} border={true}
                                              sx={{
                                                  height: "100%",
                                                  bgcolor: getCardColor(lightMode),
                                                  alignContent: "center"
                                              }}>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "100%"
                                        }}>
                                            <CircularProgress size={32} sx={{ mr: 2 }} />
                                            <Typography variant="h6">
                                                Loading One-Click Courses Setting
                                            </Typography>
                                        </Box>
                                    </MainCard>
                                ) : universitySettingsError ? (
                                    <MainCard content={true} boxShadow={true} border={true}
                                              sx={{
                                                  height: "100%",
                                                  bgcolor: getCardColor(lightMode),
                                                  alignContent: "center"
                                              }}>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "100%"
                                        }}>
                                            <ErrorOutlineIcon color="error" style={{ fontSize: 32, marginRight: 8 }} />
                                            <Typography variant="h6" color="error">
                                                Error Loading One-Click Courses Setting
                                            </Typography>
                                        </Box>
                                    </MainCard>
                                ) : (
                                    <MainCard content={true} boxShadow={true} border={false}
                                              sx={{
                                                  height: "100%",
                                                  bgcolor: getCardColor(lightMode, tenancyData.allowUnauthorisedTakeCourse)
                                              }}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "space-between"
                                        }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="When enabled, course invitation emails will include a link to take the course directly through email, circumventing the need for the user to login">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5">
                                                    One-Click Courses
                                                </Typography>
                                            </Box>
                                            <LoadingSwitch
                                                checked={tenancyData.allowUnauthorisedTakeCourse}
                                                onChange={handleOneClickCoursesToggle}
                                                inputProps={{ "aria-label": "controlled" }}
                                                disabled={isAnySwitchLoading(loadingStates)}
                                                loading={loadingStates.allowUnauthorisedTakeCourse}
                                            />
                                        </Box>
                                    </MainCard>
                                )}
                            </Grid>
                        )}

                        {/* Automatic course assignment */}
                        {isAdmin && (
                            <Grid item xs={12} md={6} lg={4}>
                                <MainCard content={true} boxShadow={true} border={false}
                                    sx={{ height: "100%", bgcolor: getCardColor(lightMode, isAutoCourseAssignmentEnabled(tenancyData.automaticCourseAssignmentDays)) }}>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "space-between"
                                    }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title="Users are automatically assigned a course following the click of a link within a phishing simulation">
                                                <IconButton>
                                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="h5">
                                                Automatic Course Assignment
                                            </Typography>
                                        </Box>
                                        <LoadingSwitch
                                            checked={isAutoCourseAssignmentEnabled(tenancyData.automaticCourseAssignmentDays)}
                                            onChange={() => handleSwitchChange('automaticCourseAssignmentDays')}
                                            loading={loadingStates.automaticCourseAssignmentDays}
                                            disabled={isAnySwitchLoading(loadingStates)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    </Box>
                                </MainCard>
                            </Grid>
                        )}

                    </Grid>

                    {/* Reports Section */}
                    {isAdmin && (
                        <>
                            <Typography variant="h4" sx={{ ml: 1, mt: 2, mb: 2 }}>Reports</Typography>
                            <Grid container spacing={2}>
                                {/* Reports */}
                                <Grid item xs={12} md={6} lg={4}>
                                    <MainCard content={true} boxShadow={true} border={false}
                                              sx={{
                                                  height: "100%",
                                                  bgcolor: getCardColor(lightMode, tenancyData.enableReporting) // reportingBox ? (lightMode ? "primary.white" : "#69686b") : (lightMode ? "grey.200" : "grey.700")
                                              }}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "space-between"
                                        }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Enable the use of Reports for Admins">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5">
                                                    Reports
                                                </Typography>
                                            </Box>
                                            <LoadingSwitch
                                                checked={tenancyData.enableReporting}
                                                onChange={() => handleSwitchChange('enableReporting')}
                                                inputProps={{ "aria-label": "controlled" }}
                                                disabled={isAnySwitchLoading(loadingStates)}
                                                loading={loadingStates.enableReporting}
                                            />
                                        </Box>
                                    </MainCard>
                                </Grid>

                                {/* Refresh reports */}
                                <Grid item xs={12} md={6} lg={4}>
                                    <MainCard content={true} boxShadow={true} border={false}
                                        sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "space-between"
                                        }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title="Manually refresh Reports access following recent changes">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5">
                                                    Refresh Reports
                                                </Typography>
                                            </Box>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleRefreshReports}
                                                disabled={isRefreshing}
                                            >
                                                {isRefreshing ? <CircularProgress size={24} color="inherit" /> : "REFRESH"}
                                            </Button>
                                        </Box>
                                    </MainCard>
                                </Grid>

                                {/* Anonymize reports */}
                                <Grid item xs={12} md={6} lg={4}>
                                    <MainCard content={true} boxShadow={true} border={false}
                                              sx={{
                                                  height: "100%",
                                                  bgcolor: getCardColor(lightMode, tenancyData.anonymiseReports) //anonBox ? (lightMode ? "primary.white" : "grey.600") : (lightMode ? "grey.200" : "grey.700")
                                              }}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "space-between"
                                        }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Tooltip title=" Anonymize all data within Reports. Effective immediately">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5">
                                                    Anonymize Reports
                                                </Typography>
                                            </Box>
                                            <LoadingSwitch
                                                checked={tenancyData.anonymiseReports}
                                                onChange={() => handleSwitchChange('anonymiseReports')}
                                                inputProps={{ "aria-label": "controlled" }}
                                                disabled={isAnySwitchLoading(loadingStates)}
                                                loading={loadingStates.anonymiseReports}
                                            />
                                        </Box>
                                    </MainCard>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {/* On Demand Section */}
                    {isAdmin && (
                        <>
                    <Typography variant="h4" sx={{ ml: 1, mt: 2, mb: 2 }}>On Demand</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MainCard content={true} boxShadow={true} border={false}
                                      sx={{
                                          height: "100%",
                                          bgcolor: getCardColor(lightMode, tenancyData.enableOndemand) //onDemandBox ? (lightMode ? "primary.white" : "grey.600") : (lightMode ? "grey.200" : "grey.700")
                                      }}>
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "space-between"
                                }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Tooltip title="Enable or disable on-demand features">
                                            <IconButton>
                                                <HelpIcon sx={{ color: "secondary.main" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography variant="h5">
                                            On Demand
                                        </Typography>
                                    </Box>
                                    <LoadingSwitch
                                        checked={tenancyData.enableOndemand}
                                        onChange={() => handleSwitchChange('enableOndemand')}
                                        inputProps={{ "aria-label": "controlled" }}
                                        disabled={isAnySwitchLoading(loadingStates)}
                                        loading={loadingStates.enableOndemand}
                                    />
                                </Box>
                            </MainCard>
                        </Grid>
                    </Grid>
                        </>
                    )}



                    {/* Custom Email Header and Reminders Sections */}
                    <Grid container spacing={2} sx={{ mb: 6 }}>
                        {/* Custom Email Header */}
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                                <Typography variant="h4" sx={{ ml: 1 }}>
                                    Custom Email Header
                                </Typography>
                                <Tooltip title="Optional email headers to be included in each email sent from the portal">
                                    <IconButton>
                                        <HelpIcon sx={{ color: 'secondary.main' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <MainCard content={true} boxShadow={true} border={false}
                                sx={{ height: "80%", bgcolor: getCardColor(lightMode) }}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title="Key must only contain letters (a-z, A-Z), numbers (0-9), hyphens (-), and underscores (_).">
                                                <IconButton size="small">
                                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="h5" sx={{ ml: 1 }}>
                                                Email Header Key
                                            </Typography>
                                        </Box>
                                        <TextField
                                            placeholder="INSERT HERE"
                                            size="small"
                                            sx={{ width: "50%" }}
                                            onChange={(e) => handleEmailHeaderChange("key", e.target.value)}
                                            value={tenancyData.customEmailHeaderKey}
                                            error={validHeaderKey === -1}
                                            helperText={emailHeaderKeyError}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title={"Value must only contain letters, numbers, and special characters (_ :;.,\\/\"'?!()[]@<>=-+*#$&`|~^%)."}>
                                                <IconButton size="small">
                                                    <HelpIcon sx={{ color: "secondary.main" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="h5" sx={{ ml: 1 }}>
                                                Email Header Value
                                            </Typography>
                                        </Box>
                                        <TextField
                                            placeholder="INSERT HERE"
                                            size="small"
                                            sx={{ width: "50%" }}
                                            onChange={(e) => handleEmailHeaderChange("value", e.target.value)}
                                            value={tenancyData.customEmailHeaderValue}
                                            error={validHeaderValue === -1}
                                            helperText={emailHeaderValueError}
                                        />
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleSave(tenancyData)}
                                            sx={{ px: 3, whiteSpace: 'nowrap', maxWidth: '200px' }}
                                        >
                                            SAVE EMAIL HEADERS
                                        </Button>
                                    </Box>
                                </Box>
                            </MainCard>
                        </Grid>

                        {/* Reminders */}
                        <Grid item xs={12} sm={12} lg={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                                <Typography variant="h4" sx={{ ml: 1 }}>
                                    Reminders
                                </Typography>
                                <Tooltip title="Set how often users will be emailed a reminder to complete any outstanding courses">
                                    <IconButton>
                                        <HelpIcon sx={{ color: 'secondary.main' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            {tenancyReminderLoading ? (
                                <MainCard content={true} boxShadow={false} border={true}
                                          sx={{ height: "80%", bgcolor: "background.default", alignContent: "center" }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                        <CircularProgress size={32} sx={{ mr: 2 }} />
                                        <Typography variant="h6">
                                            Loading Reminder Options
                                        </Typography>
                                    </Box>
                                </MainCard>
                            ) : tenancyReminderError ? (
                                <MainCard content={true} boxShadow={false} border={true}
                                          sx={{ height: "80%", bgcolor: "background.default", alignContent: "center" }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                        <ErrorOutlineIcon color="error" style={{ fontSize: 32, marginRight: 8 }} />
                                        <Typography variant="h6" color="error">
                                            Error Loading Reminder Options
                                        </Typography>
                                    </Box>
                                </MainCard>
                            ) : (
                                <MainCard content={true} boxShadow={true} border={false}
                                            sx={{ height: "80%", bgcolor: getCardColor(lightMode) }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Tooltip title="The number of days after the first assignment to send a reminder">
                                                    <IconButton size="small">
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5" sx={{ ml: 1 }}>
                                                    First Reminder
                                                </Typography>
                                            </Box>
                                            <FormControl sx={{ width: "50%" }}>
                                                <Select
                                                    variant="outlined"
                                                    value={reminderOptions.daysToRemindAfter.toString()}
                                                    onChange={(e) => {
                                                        setReminderOptions({
                                                            ...reminderOptions,
                                                            daysToRemindAfter: parseInt(e.target.value)
                                                        });
                                                    }}
                                                    size="small">
                                                    <MenuItem value="0">0</MenuItem>
                                                    <MenuItem value="7">7</MenuItem>
                                                    <MenuItem value="14">14</MenuItem>
                                                    <MenuItem value="21">21</MenuItem>
                                                    <MenuItem value="28">28</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Tooltip title="The number of days reminders will reoccur after. e.g. 7 days after first reminder">
                                                    <IconButton size="small">
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5" sx={{ ml: 1 }}>
                                                    Following Reminders
                                                </Typography>
                                            </Box>
                                            <FormControl sx={{ width: "50%" }}>
                                                <Select
                                                    value={reminderOptions.reminderFrequency?.toString()}
                                                    onChange={(e) => {
                                                        setReminderOptions({
                                                            ...reminderOptions,
                                                            reminderFrequency: parseInt(e.target.value)
                                                        });
                                                    }}
                                                    size="small"
                                                 variant="outlined">
                                                    <MenuItem value="0">0</MenuItem>
                                                    <MenuItem value="7">7</MenuItem>
                                                    <MenuItem value="14">14</MenuItem>
                                                    <MenuItem value="21">21</MenuItem>
                                                    <MenuItem value="28">28</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                onClick={handleSendReminders}
                                                disabled={isSendingReminders}
                                                sx={{ px: 3, mr: 2, whiteSpace: 'nowrap', maxWidth: '200px' }}
                                            >
                                                {isSendingReminders ? <CircularProgress size={24} color="inherit" /> : "SEND NOW"}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSaveReminderOptions}
                                                sx={{ px: 3, whiteSpace: 'nowrap', maxWidth: '200px' }}
                                            >
                                                {isSavingReminders ? <CircularProgress size={24} color="inherit" /> : "SAVE REMINDERS"}
                                            </Button>
                                        </Box>
                                    </Box>
                                </MainCard>
                            )}
                        </Grid>


                        {isAdmin && environmentName === 'dev' &&  (
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant="h4" sx={{ ml: 1, mt: 2, mb: 2 }}>Excel Report</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        {excelReportLoading ? (
                                            <MainCard content={true} boxShadow={true} border={false}
                                                      sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                                    <CircularProgress size={32} sx={{ mr: 2 }} />
                                                    <Typography variant="h6">
                                                        Loading Excel Report Settings
                                                    </Typography>
                                                </Box>
                                            </MainCard>
                                        ) : excelReportError ? (
                                            <MainCard content={true} boxShadow={true} border={false}
                                                      sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                                    <ErrorOutlineIcon color="error" style={{ fontSize: 32, marginRight: 8 }} />
                                                    <Typography variant="h6" color="error">
                                                        Error Loading Excel Report Settings
                                                    </Typography>
                                                </Box>
                                            </MainCard>
                                        ) : (
                                            <MainCard content={true} boxShadow={true} border={false}
                                                      sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Tooltip title="Set up email recipients for Excel reports">
                                                            <IconButton>
                                                                <HelpIcon sx={{ color: "secondary.main" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Typography variant="h5">
                                                            Generate Excel Report
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                                            <Typography variant="body1" sx={{ width: 100 }}>
                                                                Email(s)
                                                            </Typography>
                                                            <Box sx={{ display: 'flex', gap: 1, flex: 1 }}>
                                                                <TextField
                                                                    type="email"
                                                                    value={currentEmail}
                                                                    onChange={(e) => setCurrentEmail(e.target.value)}
                                                                    placeholder="Enter email address"
                                                                    fullWidth
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault();
                                                                            handleAddEmail();
                                                                        }
                                                                    }}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleAddEmail}
                                                                    sx={{
                                                                        minWidth: '80px',
                                                                        bgcolor: '#4cceac',
                                                                        '&:hover': {
                                                                            bgcolor: '#3da88a'
                                                                        }
                                                                    }}
                                                                >
                                                                    ADD
                                                                </Button>

                                                            </Box>
                                                        </Box>

                                                        <Box sx={{ ml: 14, display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                            {emails.map((email, index) => (
                                                                <Box
                                                                    key={index}
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        gap: 1,
                                                                        bgcolor: '#1e1e1e',
                                                                        borderRadius: 1,
                                                                        p: 1,
                                                                    }}
                                                                >
                                                                    <Typography sx={{ color: '#fff' }}>{email}</Typography>
                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => handleRemoveEmail(email)}
                                                                        sx={{
                                                                            ml: 'auto',
                                                                            color: '#fff',
                                                                            '&:hover': {
                                                                                color: '#ff0000'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Box>
                                                            ))}
                                                        </Box>

                                                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleSaveExcelSettings}
                                                                disabled={isSavingExcelSettings}
                                                            >
                                                                {isSavingExcelSettings ? (
                                                                    <CircularProgress size={24} color="inherit" />
                                                                ) : (
                                                                    "SAVE SETTINGS"
                                                                )}
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleGenerateReport}
                                                                disabled={isGeneratingReport}
                                                                sx={{
                                                                    minWidth: '80px',
                                                                    bgcolor: '#4cceac',
                                                                    '&:hover': {
                                                                        bgcolor: '#3da88a'
                                                                    }
                                                                }}
                                                            >
                                                                {isGeneratingReport ? <CircularProgress size={24} color="inherit" /> : "SEND NOW"}
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </MainCard>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {isAdmin && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant="h4" sx={{ ml: 1, mt: 2, mb: 2 }}>Admin Actions</Typography>
                                <Grid container spacing={2}>
                                    {/* Reports */}
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <MainCard content={true} boxShadow={true} border={false}
                                            sx={{
                                                height: "100%",
                                                bgcolor: getCardColor(lightMode)
                                            }}>
                                            <Box sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                justifyContent: "space-between"
                                            }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Tooltip title="Deleting the active journey will permanently remove it and all associated data. This action is irreversible. Ensure you have saved any necessary data before proceeding">
                                                        <IconButton>
                                                            <HelpIcon sx={{ color: "secondary.main" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Typography variant="h5">
                                                        Delete active Journey
                                                    </Typography>
                                                </Box>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={handleDeleteJourneysSchedules}
                                                    disabled={isDeletingJourney}
                                                >
                                                    {isDeletingJourney ? <CircularProgress size={24} color="inherit" /> : "DELETE"}
                                                </Button>
                                            </Box>
                                        </MainCard>
                                    </Grid>

                                    {/* Refresh reports */}
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <MainCard content={true} boxShadow={true} border={false}
                                            sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                                            <Box sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                justifyContent: "space-between"
                                            }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Tooltip title="Deleting all tenancy users will permenantly remove all users and their associated data. This action is irreversible. Ensure you have saved any necessary data before proceeding">
                                                        <IconButton>
                                                            <HelpIcon sx={{ color: "secondary.main" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Typography variant="h5">
                                                        Delete all tenancy users
                                                    </Typography>
                                                </Box>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={handleDeleteUsers}
                                                    disabled={isDeletingUsers}
                                                >
                                                    {isDeletingUsers ? <CircularProgress size={24} color="inherit" /> : "DELETE"}
                                                </Button>
                                            </Box>
                                        </MainCard>
                                    </Grid>

                                    {/* Anonymize reports */}
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <MainCard content={true} boxShadow={true} border={false}
                                            sx={{
                                                height: "100%",
                                                bgcolor: getCardColor(lightMode) 
                                            }}>
                                            <Box sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",
                                                justifyContent: "space-between"
                                            }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Tooltip title="Deleting this tenancy will permanently remove it and all associated data. This action is irreversible. Ensure you have saved any necessary data before proceeding">
                                                        <IconButton>
                                                            <HelpIcon sx={{ color: "secondary.main" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Typography variant="h5">
                                                        Delete tenancy
                                                    </Typography>
                                                </Box>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={handleDeleteTenancy}
                                                    disabled={isDeletingTenancy}
                                                >
                                                    {isDeletingTenancy ? <CircularProgress size={24} color="inherit" /> : "DELETE"}
                                                </Button>
                                            </Box>
                                        </MainCard>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {isAdmin && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant="h4" sx={{ ml: 1, mt: 2, mb: 2 }}>Licences</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <MainCard content={true} boxShadow={true} border={false}
                                            sx={{ height: "100%", bgcolor: getCardColor(lightMode) }}>
                                            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                                <Tooltip title="Set the number of user licenses for this tenancy">
                                                    <IconButton>
                                                        <HelpIcon sx={{ color: "secondary.main" }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="h5">
                                                    User Licenses
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <Typography variant="body1">
                                                    Current Usage: {tenancyData.adminCount + tenancyData.userCount}
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    value={tenancyData.userLicenses}
                                                    onChange={(e) => setTenancyData({
                                                        ...tenancyData,
                                                        userLicenses: parseInt(e.target.value)
                                                    })}
                                                    sx={{ width: 100 }}
                                                />
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSaveUserLicenses}
                                                >
                                                    Save Licenses
                                                </Button>
                                            </Box>
                                        </MainCard>
                                    </Grid>

                                </Grid>

                            </Grid>
                            )}
                    </Grid>
                </Grid>
            </Grid>

            {/* Snackbar for notifications */}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled"
                       sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogTitle}
                content={dialogContent}
                onConfirm={() => {
                    setDialogOpen(false);
                    dialogAction();
                }}
                onCancel={() => {
                    dialogCancelAction?.();
                    setDialogOpen(false);
                }}
            />
        </>
    );
}
