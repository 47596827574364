import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Box, Typography, Divider, Alert, Grid, Snackbar, Button, Modal, Paper, TextField, CircularProgress } from "@mui/material";
import ContentPackForm from "./ContentPackForm";
import {
    useCreateContentPackMutation,
    useUpdateContentPackMutation,
    useGetContentPackByIdQuery
} from '../../store/services/university-service';
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import ConfirmationDialog from "../../utils/confirmationDialog";

export default function ContentPackDetails() {
    const { id } = useParams();
    const location = useLocation();
    const isPreset = new URLSearchParams(location.search).get('preset') === 'true';
    const isEditMode = Boolean(id);
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [nameModalOpen, setNameModalOpen] = useState(!isEditMode);
    const [contentPackName, setContentPackName] = useState('');
    const [nameError, setNameError] = useState('');
    const [selectedCourses, setSelectedCourses] = useState([]);

    const {
        data: contentPackData,
        isLoading: isLoadingContentPack
    } = useGetContentPackByIdQuery(id, {
        skip: !isEditMode
    });

    const [createContentPack] = useCreateContentPackMutation();
    const [updateContentPack] = useUpdateContentPackMutation();

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const { tenancyId } = useSelector((state) => state.tenancy);

    useEffect(() => {
        if (isEditMode && contentPackData) {
            setContentPackName(contentPackData.contentPackName);
            setSelectedCourses(contentPackData.courseIds || []); 
        }
    }, [isEditMode, contentPackData]);

    const initialData = React.useMemo(() => {
        if (isEditMode) {
            if (isLoadingContentPack) {
                return null; 
            }
            return {
                contentPackName: contentPackData?.name || '',
                courseIds: contentPackData?.courseIds || [],
                tenancyId: contentPackData?.tenancyId || (isPreset ? 0 : tenancyId),
                published: contentPackData?.published || false
            };
        }
        return {
            contentPackName: contentPackName,
            courseIds: selectedCourses,
            tenancyId: isPreset ? 0 : tenancyId,
            published: false
        };
    }, [isEditMode, contentPackData, contentPackName, selectedCourses, isPreset, tenancyId, isLoadingContentPack]);

    if (isEditMode && isLoadingContentPack) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    const handleNavigateBack = () => {
        if (isSaving) {
            setDialogOpen(true);
            setDialogAction(() => () => navigate(-1));
            return;
        }
        navigate(-1);
    };

    const handleNameSubmit = () => {
        if (!contentPackName.trim()) {
            setNameError('Content Pack Name is required');
            return;
        }
        setNameModalOpen(false);
    };

    const handleFormSave = (data) => {
        setSelectedCourses(data.content);
    };

    const handleSaveContentPack = async () => {
        if (!contentPackName.trim() && !isEditMode) {
            setSnackbarSeverity("error");
            setSnackbarMessage("Content Pack Name is required");
            setSnackbarOpen(true);
            return;
        }

        if (selectedCourses.length === 0) {
            setSnackbarSeverity("error");
            setSnackbarMessage("Please select at least one course");
            setSnackbarOpen(true);
            return;
        }

        setIsSaving(true);
        try {
            const transformedData = isEditMode ? {
                name: contentPackName,
                tenancyId: contentPackData.tenancyId, 
                published: false,
                courseIds: selectedCourses
            } : {
                name: contentPackName,
                tenancyId: isPreset ? 0 : tenancyId, 
                published: false,
                courseIds: selectedCourses
            };

            const result = isEditMode
                ? await updateContentPack({
                    id: id,
                    contentPackData: transformedData
                }).unwrap()
                : await createContentPack(transformedData).unwrap();

            setSnackbarSeverity("success");
            setSnackbarMessage(`Content pack ${isEditMode ? 'updated' : 'created'} successfully!`);
            setSnackbarOpen(true);
            setIsSaving(false);

            await new Promise(resolve => setTimeout(resolve, 500));
            navigate('/packs');

        } catch (error) {
            console.error('Error saving content pack:', error);
            const errorMessage = error.data?.message ||
                `Failed to ${isEditMode ? 'update' : 'create'} content pack`;

            setSnackbarSeverity("error");
            setSnackbarMessage(errorMessage);
            setSnackbarOpen(true);
            setIsSaving(false);
        }
    };

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            overflow: 'auto'
        }}>
            <Grid container rowSpacing={2.5} columnSpacing={2.75}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Button
                            onClick={handleNavigateBack}
                            variant="contained"
                            startIcon={<KeyboardArrowLeftTwoToneIcon />}
                        >
                            Back
                        </Button>
                        {!nameModalOpen && ( 
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleSaveContentPack}
                                disabled={(!contentPackName && !isEditMode) || selectedCourses.length === 0 || isSaving}
                            >
                                {isSaving ? (
                                    <>
                                        <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                                        {isEditMode ? 'Updating...' : 'Creating...'}
                                    </>
                                ) : (
                                    isEditMode ? 'Update Content Pack' : 'Create Content Pack'
                                )}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" sx={{ mb: 4 }}>
                        <Typography variant="h2" mb={2}>
                            {isEditMode
                                ? contentPackData
                                    ? `${contentPackData.contentPackName}`
                                    : "Loading..."
                                : `${contentPackName ? `${contentPackName}` : ''}`}
                        </Typography>
                        <Divider sx={{ width: "100%" }} />
                    </Grid>
                    <ContentPackForm
                        initialData={initialData}
                        onSave={handleFormSave}  
                        mode={isEditMode ? "edit" : "add"}
                        isSaving={isSaving}
                    />
                </Grid>

            </Grid>

            <Modal
                open={nameModalOpen}
                disableEscapeKeyDown
                disableBackdropClick
                aria-labelledby="content-pack-name-modal"
            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                    width: 400,
                    p: 4,
                    outline: 'none',
                    backgroundColor: 'background.default'
                }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Enter Content Pack Name
                    </Typography>
                    <TextField
                        fullWidth
                        label="Content Pack Name"
                        value={contentPackName}
                        onChange={(e) => {
                            setContentPackName(e.target.value);
                            if (e.target.value.trim()) setNameError('');
                        }}
                        error={!!nameError}
                        helperText={nameError}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        onClick={handleNameSubmit}
                        disabled={!contentPackName.trim()}
                    >
                        Continue
                    </Button>
                </Paper>
            </Modal>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <ConfirmationDialog
                open={dialogOpen}
                title="Unsaved Changes"
                content="You have unsaved changes. Are you sure you want to leave?"
                onConfirm={() => {
                    setDialogOpen(false);
                    dialogAction();
                }}
                onCancel={() => setDialogOpen(false)}
            />
        </Box>
    );
}