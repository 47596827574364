import React, { useRef, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    IconButton,
    InputAdornment,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Menu,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import { SearchOutlined, FilterAltOutlined } from "@mui/icons-material";
import ViewWeekTwoToneIcon from "@mui/icons-material/ViewWeekTwoTone";
import PropTypes from "prop-types";
import { useGetValidCoursesQuery, useUploadToS3Mutation } from "../../store/services/university-service";
import { useSelector } from "react-redux";
import { ReactComponent as FilterColumns } from "../../assets/images/icons/filterColumns.svg";

TableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    searchQuery: PropTypes.string.isRequired,
    setSearchQuery: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    cellNames: PropTypes.array.isRequired,
    selectedCellNames: PropTypes.array.isRequired,
    setSelectedCellNames: PropTypes.func.isRequired,
    showSearch: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    editColumn: PropTypes.bool.isRequired,
    toolbarActions: PropTypes.array,
    showToolbarActions: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
    showDelete: PropTypes.bool,
    serverSideSearch: PropTypes.bool,
    CSVButton: PropTypes.shape({
        label: PropTypes.string,
        enabled: PropTypes.bool,
        onClick: PropTypes.func
    }),
    isAdmin: PropTypes.bool
};

export default function TableToolbar(props) {
    const {
        numSelected,
        searchQuery,
        setSearchQuery,
        name,
        cellNames,
        selectedCellNames,
        setSelectedCellNames,
        showSearch,
        title,
        editColumn,
        toolbarActions,
        showToolbarActions,
        onDelete,
        showDelete,
        serverSideSearch,
        CSVButton,
        isAdmin
    } = props;

    const [uploadToS3] = useUploadToS3Mutation();
    const [anchorEl, setAnchorEl] = useState(null);
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const fileInputRef = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleSendUsingCSV(file);
        }
    };

    const handleToggleColumn = (columnName) => {
        setSelectedCellNames((prev) =>
            prev.includes(columnName)
                ? prev.filter((name) => name !== columnName)
                : [...prev, columnName]
        );
    };

    const handleSendUsingCSV = async (file) => {
        if (file.type === "application/vnd.ms-excel" || file.type === "text/csv") {
            await uploadToS3({ tenancyId: tenancyId, file: file }).unwrap().then((response) => {
                    console.log("Upload to S3 response:", response);
                    if (response.success) {
                        setSnackbarMessage("Users uploaded successfully.");
                        setSnackbarSeverity("success");
                    } else {
                        setSnackbarMessage("Error uploading users.");
                        setSnackbarSeverity("error");
                    }
                    setSnackbarOpen(true);
                }
            ).catch((error) => {
                console.log("Error uploading to S3:", error);
                setSnackbarMessage("Error uploading users.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
        } else {
            setSnackbarMessage("Invalid file type. Please upload a CSV file.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: "1 1 100%" }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                            {showSearch ? (
                                <OutlinedInput
                                    sx={{ width: "100%", mr: 1 }}
                                    size="small"
                                    id="header-search"
                                    startAdornment={
                                        <InputAdornment position="start" sx={{ mr: -0.5 }}>
                                            <SearchOutlined />
                                        </InputAdornment>
                                    }
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder={`Search ${name}`}
                                />
                            ) : (
                                <Typography color="inherit" variant="h5" alignSelf="center">
                                    {title}
                                </Typography>
                            )}
                            {editColumn && (
                                <Tooltip title="Select Columns">
                                    <IconButton aria-describedby={id} onClick={handleClick}>
                                        <FilterColumns color="primary" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>

                        <Box display="flex" alignItems="center">
                            {CSVButton && isAdmin && (
                                <>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        color="secondary"
                                        sx={{ bgcolor: (theme) => theme.palette.secondary.lighter, mr: 1, mb: 1 }}
                                        onClick={() => fileInputRef.current.click()}
                                    >
                                        send using csv
                                    </Button>
                                </>
                            )}
                            {showToolbarActions && toolbarActions && toolbarActions.map((toolbar, index) => (
                                <Box key={index}>
                                    {toolbar.render()}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
                {numSelected > 0 && (
                    <>
                        {showDelete && (
                            <Button size="medium" variant="contained" color="warning"
                                sx={{ textTransform: "capitalize", pr: 2, pl: 2 }} onClick={onDelete}>
                                Delete
                            </Button>
                        )}
                    </>
                )}
            </Box>

            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {cellNames
                    .filter(name => name.id !== 'select')
                    .map((name) => (
                        <MenuItem
                            key={name.id}
                            sx={{ width: "100%", pr: 10 }}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleToggleColumn(name.label);
                            }}
                        >
                            <Checkbox
                                checked={selectedCellNames.indexOf(name.label) > -1}
                            />
                            <ListItemText primary={name.label} />
                        </MenuItem>
                    ))}
            </Menu>
        </Toolbar >
    );
}