import MainLayout from "../layout/MainLayout";
import UsersDefault from "../pages/users";
import User from "../pages/user";
import OnDemand from "../pages/on-demand";
import Reports from "../pages/reports";
import TenancyManagement from "../pages/tenancy";
import SimulationDetails from "../pages/simulations/SimulationDetails";
import ContentPacks from "../pages/content-packs";
import ContentPackDetails from "../pages/content-packs/ContentPackDetails";


const TenancyAdminRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: 'users',
            element: <UsersDefault />
        },
        {
            path: 'users/:id',
            element: <User />
        },
        {
            path: 'settings',
            element: <TenancyManagement />
        },
        {
            path: 'on-demand',
            element: <OnDemand />
        },
        {
            path: 'packs',
            element: <ContentPacks />
        },
        {
            path: "packs/add",
            element: <ContentPackDetails />
        },
        {
            path: "packs/add/:preset",
            element: <ContentPackDetails />
        },
        {
            path: "packs/edit/:id",
            element: <ContentPackDetails />
        },
        {
            path: 'reports',
            element: <Reports />
        },
        {
            path: "simulations/edit/:id",
            element: <SimulationDetails />
        }
    ]
};

export default TenancyAdminRoutes;
