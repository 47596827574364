import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDynamicBreakpoints } from '../../utils/breakpointUtils';
import { useSelector } from 'react-redux';
import { getTextColor } from '../../utils/colorUtils';

const Card = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 6/8;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
    height: 250px;
    max-width: 200px;
    
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    ${({ theme }) => `
        background-color: ${theme.palette.mode === `light` ? `#f0f0f0` : `#000000`};
        box-shadow: 0 5px 5px ${theme.palette.mode === `light` ? `rgba(0, 0, 0, 0.1)` : `undefined`};
        &:hover {
            transform: scale(1.02);
            box-shadow: 0 10px 20px ${theme.palette.mode === `light` ? `rgba(0, 0, 0, 0.5)` : `undefined`};
        }
    `}

`;

const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
        transform: ${({ isHovered }) => (isHovered ? "scale(1.1)" : "scale(1)")};
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ isHovered }) => (isHovered ? "rgba(0, 0, 0, 0.6)" : "transparent")};
    transition: background 0.3s ease;
`;

const Content = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    background:  ${({ theme, isHovered }) =>
        isHovered ? "transparent" : theme.palette.mode === "dark" ?
            theme.palette.grey[500] : theme.palette.primary.dark};
    transition: background 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
`;

const Title = styled.h2`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: "Inter", sans-serif;
    color:  ${({ theme, isHovered }) =>
        isHovered ?
            theme.palette.white :
            getTextColor(theme.palette.primary.dark)};
    transition: all 0.3s ease;
    ${({ isHovered }) => isHovered
        ? css`
                white-space: normal;
                overflow: visible;
                text-overflow: clip;
            `
        : css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            `
    }
`;

const Description = styled.p`
    margin: 5px 0 0;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    color:  ${({ theme, isHovered }) =>
        isHovered ?
            theme.palette.white :
            getTextColor(theme.palette.primary.dark)};
    transition: color 0.3s ease;

    @media (max-width: 768px) {
        font-size: 10px;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 5px;
`;

const ContentPackCardComponent = ({ image, title, dateLabel, dateValue, gif, onClick, type, isPreview = false }) => {
    const theme = useTheme();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { matchDownSM, adjustedBreakpoints } = useDynamicBreakpoints(drawerOpen);
    const [isHovered, setIsHovered] = useState(false);
    const lightMode = theme.palette.mode === "light";

    return (
        <Card
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            theme={theme}
            adjustedBreakpoints={adjustedBreakpoints}
            isPreview={isPreview}
        >
            <ImageContainer isHovered={isHovered}>
                <img src={isHovered ? gif : image} alt={title} />
                <Overlay isHovered={isHovered} />
            </ImageContainer>
            <Content isHovered={isHovered} theme={theme}>
                <Title isHovered={isHovered} title={title} theme={theme}> {title}</Title>
                <Description isHovered={isHovered} theme={theme}>{dateLabel} {dateValue}</Description>
                 
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color={lightMode ? "warning" : "primary"}
                        size={matchDownSM ? "small" : "medium"}
                        sx={{
                            width: "100%",
                            ...(lightMode
                                ? {}
                                : {
                                    '&.MuiButton-containedPrimary': {
                                        bgcolor: theme.palette.primary.main,
                                        color: getTextColor(theme.palette.primary.main),
                                        '&:hover': {
                                            bgcolor: "#69686b",
                                        },
                                    },
                                }),
                        }}
                        onClick={onClick}
                    >
                        Preview
                    </Button>
                </ButtonContainer>
                
            </Content>
        </Card>
    );
};

export default ContentPackCardComponent;