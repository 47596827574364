import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useGetCourseByIdQuery } from "../../store/services/university-service";
import { APP_URL } from '../../config';
import { useModal } from '../../components/Modal/ModalContext';
 

const TabContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: -35,
    left: 0,
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: -1,
}));

const PreviewTab = styled(Box)(({ theme, index = 0 }) => ({
    width: '140px',
    height: '50px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    boxShadow: `
        0 5px 5px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'undefined'},
        5px 2px 5px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'undefined'}
    `,
    transform: `translateX(${index * -15}%)`,
    zIndex: 3 - index,
    '& .label-text': {
        transform: index === 0
            ? 'translateY(7px)'
            : `translate(${7.2}%, 7px)`,
        width: '100%',
        textAlign: 'center',
        color: theme.palette.mode === 'light' ? '#000' : '#000',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 8px'
    }
}));

const ShadowOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: -10,
    left: 0,
    right: 0,
    height: 20,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.08) 40%, transparent 100%)',
    zIndex: 0,
    pointerEvents: 'none',
}));

const ContentPackCoursePreviewModal = ({ courseId, open, onClose, isStandalone=false }) => {
    const { setActiveModal } = useModal();
    const theme = useTheme();
    const { data: courseData, isLoading, error } = useGetCourseByIdQuery({
        courseId,
        includes: ''
    });

    const handleClose = () => {
        setActiveModal(null);
        onClose();
    };

    const courseURL = courseData?.courseContentPath
        ? `${APP_URL}/${courseData.courseContentPath}`
        : '';

    return (
        <Box sx={{ position: 'relative' }}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth={false}
                    PaperProps={{
                        sx: {
                            width: '500px',
                            height: '70vh',
                            margin: '0 auto',
                            backgroundColor: 'background.default',
                            borderRadius: '10px',
                            overflow: 'visible',
                            position: 'relative',
                            zIndex: (theme) => theme.zIndex.modal + 1,
                            isolation: 'isolate',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                inset: 0,
                                background: theme.palette.background.paper,
                                borderRadius: '10px',
                                zIndex: 1
                            }
                        }
                    }}
                    sx={{
                        '& .MuiDialog-container': {
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh'
                        },
                        '& .MuiPaper-root': {
                            m: 0
                        },
                        '& .MuiBackdrop-root': {
                            display: isStandalone ? 'flex' : 'none',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }}
                >
                {open && (
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: -45,
                            bgcolor: 'background.default',
                            boxShadow: 2,
                            '&:hover': {
                                bgcolor: 'background.default',
                                opacity: 0.9
                            },
                            width: 40,
                            height: 40,
                            zIndex: 1 
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}

                <DialogContent
                    sx={{
                        p: 0,
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        borderRadius: '10px',
                        position: 'relative',
                        zIndex: 2,
                        backgroundColor: theme.palette.background.paper,
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                    }}
                >
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                        borderRadius: '10px',
                        backgroundColor: theme.palette.background.paper,
                        zIndex: 2
                    }}>
                        {isLoading && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <CircularProgress />
                            </Box>
                        )}

                        {error && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                Error loading course preview
                            </Box>
                        )}

                        {courseData && (
                            <iframe
                                src={courseURL}
                                title="Course Preview"
                                width="100%"
                                height="100%"
                                style={{
                                    border: 'none',
                                    borderRadius: '10px'
                                }}
                            />
                        )}
                    </Box>
                </DialogContent>

                <ShadowOverlay />

                <TabContainer>
                    <PreviewTab
                        index={0}
                        sx={{
                            bgcolor: theme.palette.warning.main,
                        }}
                    >
                        <span className="label-text">{courseData?.format}</span>
                    </PreviewTab>
                    <PreviewTab
                        index={1}
                        sx={{
                            bgcolor: theme.palette.primary.main,
                        }}
                    >
                        <span className="label-text">{courseData?.languageCode}</span>
                    </PreviewTab>
                </TabContainer>
            </Dialog>
        </Box>
    );
};

ContentPackCoursePreviewModal.propTypes = {
    courseId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ContentPackCoursePreviewModal;