import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RESPONSE_API_URL } from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: RESPONSE_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem('user');

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set('authorization', `Bearer ${userToken}`)
        }
        return headers
    },
    credentials: "omit"
})

export const reportApi = createApi({
    reducerPath: "reportApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getExcelReportSettings: builder.query({
            query: ({tenancyId}) => `excelreportsettings/${tenancyId}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        sendExcelReportSettings: builder.mutation({
            query: ({tenancyId, data}) => ({
                url:`excelreportsettings/${tenancyId}`,
                method: 'PUT',
                body: data,
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        sendGenerateExcelReport: builder.mutation({
            query: ({tenancyId, data}) => ({
                url:`excelreport/${tenancyId}`,
                method: 'POST',
                body: data,
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        })
    })
});

export const {
    useGetExcelReportSettingsQuery,
    useSendExcelReportSettingsMutation,
    useSendGenerateExcelReportMutation
} = reportApi;
