import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, TextField, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const FeedbackButton = ({ support = false,  }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const theme = useTheme();
    const currentUrl = window.location.href;

    // 2yf4BXeVQ is the form that emails support whereas LfZIbbGZ5 is the form that emails product
    const FORMSPARK_FORM_ID = support ? "2yf4BXeVQ" : "LfZIbbGZ5"
    const FORMSPARK_ACTION_URL = `https://submit-form.com/${FORMSPARK_FORM_ID}`;

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin((user && user.role === "Administrators") || user && user?.role?.includes("TenancyAdministrators"));
        if (user && user.email) {
            setEmail(user.email);
        }
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setMessage('');
        setName('');
    };

    return (
        <>
            <Button variant="contained" color={support ? "primary" : "warning"} onClick={handleOpen} >
                { support ? "Help" : "Feedback" }
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="feedback-modal-title"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: theme.palette.background.default,
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="feedback-modal-title" variant="h4" component="h2" gutterBottom>
                        { support ? "Provide Details of your Issue" : "Provide Feedback" }
                    </Typography>
                    <form action={FORMSPARK_ACTION_URL} method="POST">
                        <input
                            type="hidden"
                            name="_redirect"
                            value={currentUrl}
                        />
                        <input
                            type="hidden"
                            name="_email.from"
                            value={email}
                        />
                        <label htmlFor="name">Name</label>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="name"
                            name="name"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <label htmlFor="email">Email</label>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <label htmlFor="message">Message</label>
                        <TextField
                            fullWidth
                            margin="normal"
                            id="message"
                            name="message"
                            multiline
                            rows={4}
                            placeholder="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                        >
                            Send
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

export default FeedbackButton;