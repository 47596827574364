import SimulationForm from "../../simulations/SimulationForm";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useCreateSimulationMutation } from "../../../store/services/simulation-service";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

export default function AddSimulationModal({ open, handleClose }) {
    const theme = useTheme();
    const { tenancyId } = useSelector((state) => state.tenancy);
    const [success, setSuccess] = useState(false);

    const [createSimulation] = useCreateSimulationMutation();

    const initialData = {
        simulationName: "",
        emailSubject: "",
        emailDisplayName: "",
        emailFromAddress: "",
        simulationType: "Other",
        simsDomainName: "",
        difficultyRating: 2,
        published: false,
        tenancyId: tenancyId,
        content: "",
        linkedUniversityCourseId: "Phishing 101"
    };


    const handleSaveSimulation = async (simsData) => {
        try {
            simsData.name = simsData.simulationName;
            const response = await createSimulation(simsData).unwrap();
            setSuccess(response && response.message === "Ok");
        } catch (e) {
            console.error("Error creating simulation:", e);
            setSuccess(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="add-user-modal-title"
            aria-describedby="add-user-modal-description"
            disableEnforceFocus={true}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "75%",
                    height: "95%",
                    boxShadow: theme.customShadows.z1,
                    p: 4,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.borderRadius.sm,
                    overflow: "scroll",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                }}
            >
                {success ? (
                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} m={4}>
                        <CheckCircleIcon sx={{ color: "primary.main", fontSize: 80, mb: 2 }} />
                        <Typography variant="h5">Simulation added successfully</Typography>

                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={4} width={"100%"}>
                            <Button
                                sx={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                ) : (

                    <SimulationForm initialData={initialData} onSave={handleSaveSimulation} mode={"add-on-demand"} />
                )}
            </Box>

        </Modal>

    );
}