import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, TextField, MenuItem, Select, InputLabel, FormControl, Typography } from "@mui/material";
import { useGetSimulationsQuery } from "../../store/services/simulation-service";
import { useGetCoursesQuery } from "../../store/services/university-service";
import MainCard from "../../components/cards/MainCard";
import JourneyTimeline from "./JourneyTimeline";
import { useTheme } from "@mui/material/styles";
import { getCardColor } from "../../utils/colorUtils";

JourneyForm.propTypes = {
    initialData: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired
};

export default function JourneyForm(props) {
    const { initialData, onSave, mode } = props;
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const [journeyData, setJourneyData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState("");

    const [currentJourneyOption, setCurrentJourneyOption] = useState({
        day: "",
        month: "",
        type: 0,
        typeId: "",
        typeName: ""
    });

    const { data: simData } = useGetSimulationsQuery({
        perPage: 200,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });
    const { data: courseData } = useGetCoursesQuery({
        perPage: 200,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });


    // because of backend magic for type 0 (sims) we need typeName to be populated but for typeId to be an empty string
    // because of similar magic reasons type 1 (courses) need to have both typeName and typeId populated with the course name
    const isOptionValid = () => {
        return (
            (currentJourneyOption.type === 0 && currentJourneyOption.typeName !== "" && currentJourneyOption.typeId === "") ||
            (currentJourneyOption.type === 1 && currentJourneyOption.typeName === currentJourneyOption.typeId && currentJourneyOption.typeId !== "") &&
            currentJourneyOption.month >= 1 &&
            currentJourneyOption.month <= 12 &&
            currentJourneyOption.day >= 1 &&
            currentJourneyOption.day <= 31
        );
    };

    const handleAddOption = () => {
        setJourneyData({
            ...journeyData,
            journeyOptions: [...journeyData.journeyOptions, currentJourneyOption]
        });
        setCurrentJourneyOption({
            day: "",
            month: "",
            type: 0,
            typeId: "",
            typeName: ""
        });
    };

    const handleDeleteOption = (index) => {
        const updatedOptions = journeyData.journeyOptions.filter((_, i) => i !== index);
        setJourneyData({
            ...journeyData,
            journeyOptions: updatedOptions
        });
    };

    const handleSave = () => {
        if (!journeyData.name.trim()) {
            setNameError("Journey Name is required");
            return;
        }
        setLoading(true);
        onSave({ ...journeyData });
        setLoading(false);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    label="Journey Name"
                    value={journeyData.name}
                    size="small"
                    sx={{ mb: 4, width: "50%" }}
                    onChange={(e) => {
                        setJourneyData({ ...journeyData, name: e.target.value });
                        if (e.target.value.trim()) setNameError("");
                    }}
                    required
                    error={!!nameError}
                    helperText={nameError}
                />

                <Grid item xs={12}>
                    <MainCard content={true} boxShadow={false} border={false} sx={{ height: "100%", bgcolor: lightMode  ? "primary.main" : getCardColor(lightMode) }}>
                        <Typography variant="h5" gutterBottom>
                            Add Simulation or Course
                        </Typography>

                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%", mt: 4, gap: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel style={{ backgroundColor: "transparent" }}>Type</InputLabel>
                                <Select
                                    value={currentJourneyOption.type}
                                    onChange={(e) => setCurrentJourneyOption({ ...currentJourneyOption, type: e.target.value, typeId: "", typeName: "" })}
                                    label="Type"
                                    variant="outlined"
                                    required={true}
                                >
                                    <MenuItem value={0}>Simulation</MenuItem>
                                    <MenuItem value={1}>Course</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel
                                    style={{ backgroundColor: "transparent" }}>Select {currentJourneyOption.type === 1 ? "Course" : "Simulation"}</InputLabel>
                                <Select
                                    value={currentJourneyOption.type === 0 ? currentJourneyOption.typeName : currentJourneyOption.typeId}
                                    onChange={(e) => {
                                        if (currentJourneyOption.type === 0) {
                                            setCurrentJourneyOption({
                                                ...currentJourneyOption,
                                                typeName: e.target.value,
                                                typeId: "" 
                                            });
                                        } else {
                                            setCurrentJourneyOption({
                                                ...currentJourneyOption,
                                                typeId: e.target.value,
                                                typeName: e.target.value
                                            });
                                        }
                                    }}
                                    label={"Select " + (currentJourneyOption.type === 1 ? "Course" : "Simulation")}
                                    variant="outlined"
                                    required={true}
                                >
                                    {currentJourneyOption.type === 1 && courseData && courseData.map((course, index) => (
                                        <MenuItem key={index} value={course.courseName}>
                                            {course.courseName}
                                        </MenuItem>
                                    ))}
                                    {currentJourneyOption.type === 0 && simData && simData.map((sim, index) => (
                                        <MenuItem key={index} value={sim.simulationName}>
                                            {sim.simulationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                type="number"
                                label="Month"
                                value={currentJourneyOption.month}
                                onChange={(e) => setCurrentJourneyOption({
                                    ...currentJourneyOption,
                                    month: e.target.value > 12 ? 12 : e.target.value
                                })}
                                inputProps={{ min: 1, max: 12 }}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ style: { backgroundColor: "transparent" } }}
                            />
                            <TextField
                                type="number"
                                label="Day"
                                value={currentJourneyOption.day}
                                onChange={(e) => setCurrentJourneyOption({
                                    ...currentJourneyOption,
                                    day: e.target.value > 31 ? 31 : e.target.value
                                })}
                                inputProps={{ min: 1, max: 31 }}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ style: { backgroundColor: "transparent" } }}
                            />
                            <Button color="warning" variant="contained" size="medium" disabled={!isOptionValid()} onClick={handleAddOption}
                                    sx={{ height: "fit-content", alignSelf: "center" }}>
                                Add
                            </Button>
                        </Box>
                    </MainCard>
                </Grid>

                <Grid item xs={6}>
                    <Button color="warning" variant="contained" onClick={handleSave} sx={{ width: "50%", mt: 4 }}>
                        {loading ? "Loading..." : mode === "view" ? "Update Journey" : "Create Journey"}
                    </Button>
                </Grid>

                <JourneyTimeline journeyData={journeyData} handleDeleteOption={handleDeleteOption} />
            </Grid>
        </Grid>
    );
};